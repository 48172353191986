
import * as world_base from '../word_base.json'
import { rmaccent } from '../utils';

function remakeID(list) {
  let i = 0
  list.forEach(e => {
    e.id = i
    i++;
  });
  return (list)
}

const log_info_default = {log: false};
const defaultName = {first: "", last: ""}
const defaultSelectedPatient = () => ({
  id: null,
  str: "Pas de patient selectionné",
  name: defaultName,
  email: "",
  trained_words: {},
  lastTrained: -1,
  untrained_list: [],
  learning_list: [],
  learnt_list: [],
  patientType : null,
})

const default_display_list = [...Array(world_base.default.words.length).keys()]

const Default_state = {
  list: remakeID(world_base.default.words.sort(function (a, b) {
    return (a.mot.localeCompare(b.mot))
  })),
  categories: world_base.default.categories,

  syllables_filter: [1, 4],
  search: '',

  dynamic_select: false,

  display_lists: {
    untrained: default_display_list,
    learning: [],
    learnt: []
  },

  list_out: [],

  log_info: log_info_default,
  SelectedPatient: defaultSelectedPatient(),
  AskFeedBack: false,
}

export function idIndex(id, l_obj) {
  for (let i in l_obj) {
    if (l_obj[i].id === id)
      return i
  }
  return -1

}

const filterLists = (state) => {
  console.log(state.dynamic_select)
  const filter = idx => {
    const word = state.list[idx]
    let isDynamicFiltered = false
    if (state.dynamic_select === true)
      isDynamicFiltered = word.category !== undefined && state.list_out.find(idx => state.list[idx].category === word.category) !== undefined

    return (
      word.nSyllables >= state.syllables_filter[0] &&
      word.nSyllables <= state.syllables_filter[1] &&
      rmaccent(word.mot).includes(rmaccent(state.search)) &&
      !isDynamicFiltered
    )
  }

  const selPatient = state.SelectedPatient
  const untrained_list = selPatient.id !== null ? selPatient.untrained_list : default_display_list
  return {
    untrained: untrained_list.filter(filter),
    learning: selPatient.learning_list.filter(filter),
    learnt: selPatient.learnt_list.filter(filter),
  }
}

export default function Reducer(state = Default_state, action) {
  let i
  let obj;

  switch (action.type) {

    case 'CHANGE_FILTER':
      if (action.syllables !== undefined)
        state.syllables_filter = action.syllables
      if (action.search !== undefined)
        state.search = rmaccent(action.search.trim())

      return {
        ...state,
        display_lists: filterLists(state)
      }

    case 'ADD_LST_OUT_REMOV':
      i = state.list_out.findIndex(id => action.word.id == id)
      if (i == -1)
        state.list_out.push(action.word.id)
      else
        state.list_out.splice(i, 1)

      if (state.dynamic_select)
        state.display_lists = filterLists(state)
      return {...state, list_out: state.list_out.slice()}


    case 'REPLACE_LST_OUT':
        state.list_out = action.nlist
      if (state.dynamic_select)
        state.display_lists = filterLists(state)
      return {
        ...state,
      }

    case 'LOGIN':
      let n_log_info = {...state.log_info}
      n_log_info.log = true
      Object.assign(n_log_info, action.response.data.user)
      console.log(state)
      return {...state, log_info: n_log_info}

    case 'LOG_OUT':
      sessionStorage.removeItem('jwtToken')
      return {...state, log_info: log_info_default, SelectedPatient: defaultSelectedPatient()}

    case 'GET_PATIEN_ID': //
      let cp = [...state.log_info.users]
      obj = {...defaultSelectedPatient(), str: action.str} //{id : null , str : action.str}
      for (let index = 0; index < cp.length; index++) {
        //   console.log(cp[index])
        if (cp[index].name.first + ' ' + cp[index].name.last === obj.str) {
          obj.id = cp[index].id
          obj.name = cp[index].name
          // GetUser(obj.id)
          return {...state, SelectedPatient: obj}
        }
      }
      return state

    case 'SYNC_LIST':
      return {...state, sync_list: [...state.list_out]}

    case 'SET_USER_INFO':
      obj = {...action.response} //{id : null , str : action.str}

      let def = {
        ...defaultSelectedPatient(),
        id: action.id,
        str: obj.data.name.first + ' ' + obj.data.name.last,
        name: obj.data.name, email: obj.data.email,
        date: obj.data.creation,
        trained_words: obj.data.trained_words ? obj.data.trained_words : {},
        patientType : obj.data.patientType,
      }

      state.SelectedPatient = def

      if(obj.data.type === 'practician')
        return {...state}

      for (let [key, word] of Object.entries(state.list)) {
        const idx = Number(key)
        const isWordTrained = def.trained_words && def.trained_words[word.mot]
        if (isWordTrained && def.trained_words[word.mot].learnt === 1)
          def.learnt_list.push(idx)
        else if (isWordTrained && def.trained_words[word.mot].learnt === 0)
          def.learning_list.push(idx)
        else
          def.untrained_list.push(idx)
      }

      return {...state, list_out: obj.data.list, sync_list: [...obj.data.list], display_lists: filterLists(state)}

    case 'RESET_DEFAULT_SELECTEDPATIEN' :
      obj =  defaultSelectedPatient()
      return{...state, SelectedPatient:obj, list_out: [], display_lists: filterLists(state)}

    case 'UPDATE_LISTS':
      state.SelectedPatient.trained_words[action.newObject.mot] = action.newObject.objectMot
      if (action.feedback === 1)
        state.SelectedPatient.learnt_list.push(action.wordIdx)
      else if (action.feedback === 0)
        state.SelectedPatient.learning_list.push(action.wordIdx)
      else
        state.SelectedPatient.untrained_list.push(action.wordIdx)

      const splice = (list) => {
        const i = list.findIndex(e => e === action.wordIdx)
        if (i > -1)
          list.splice(i, 1)
      }

      if (action.oldFeedback === 1)
        splice(state.SelectedPatient.learnt_list)
      else if (action.oldFeedback === 0)
        splice(state.SelectedPatient.learning_list)
      else
        splice(state.SelectedPatient.untrained_list)

      return {...state, display_lists: filterLists(state)}

    case 'SET_EVAL_MODE':
      return {...state, AskFeedBack: action.value}

    case 'ADD_CATEGORY':
      if (!action.category || state.categories.includes(action.category))
        return state;
      return {...state, categories: [...state.categories, action.category]}

    case 'REMOVE_CATEGORY':
      state.list = state.list.map(word => {
        if (word.category === action.category) {
          return {...word, category: undefined}
        }
        return word
      })
      state.categories.splice(action.category, 1);
      return {...state, categories: [...state.categories], list: [...state.list]}

    case 'SET_CATEGORY':
      state.list[action.wordIdx] = {...state.list[action.wordIdx], category: action.category}
      return {...state, list: [...state.list]}

    case 'TOGGLE_DYNAMIC_SELECT':
      state.dynamic_select = !state.dynamic_select
      return {...state, display_lists: filterLists(state)}

    default:
      return state
  }
}
// let store = createStore(Reducer)
