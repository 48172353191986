import React from 'react';

import Button from '@material-ui/core/Button';
import store from './reducer/store'
import TextField from '@material-ui/core/TextField';
import SubmitButton from './SubmitButton'
import { withRouter } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SendIcon from '@material-ui/icons/Send';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {SendBugReport} from './userRequests';
import Reducer from './reducer/reducer';

function RenderTypeOne(props) {
    const max_size = 500
    return (<div style={{ minHeight: '50%', display: 'flex', flexDirection: 'column', minWidth: '95%' }}>
        <TextField required multiline label="Lister le ou les mots qui posent problème" onChange={value => {(value.currentTarget.value.length < max_size) ?
            props.sFieldOne(value.currentTarget.value) :
                value.currentTarget.value =  value.currentTarget.value.substring(0,max_size);
            }} > </TextField>
        <div style={{ minHeight: '50%', display: 'flex', flexDirection: 'column', minWidth: '95%' }}>
            <TextField  multiline label="Détails" onChange={value => {(value.currentTarget.value.length < max_size) ?
                props.sFieldTwo(value.currentTarget.value) :
                value.currentTarget.value = value.currentTarget.value.substring(0,max_size);
            }}></TextField>
        </div>
    </div>);
}

function RenderTypeTwo(props) {
    const max_size = 500
    return (
    <div style={{ minHeight: '50%', display: 'flex', flexDirection: 'column', minWidth: '95%' }}>
         <TextField required multiline label="Détails" onChange={value => {(value.currentTarget.value.length < max_size) ?
            props.sFieldOne(value.currentTarget.value) :
                value.currentTarget.value =  value.currentTarget.value.substring(0,max_size);
            }} > </TextField>
    </div>);
}


function Render(props) {
  if (props.type === 0 || props.type === 1)
      return (<RenderTypeOne sFieldOne={props.FieldOne} sFieldTwo={props.FieldTwo} />)
  return (<RenderTypeTwo sFieldOne={props.FieldOne} />);
}

function ReportBug() {
    const [type, setType] = React.useState(0);
    const [fieldOne, sFieldOne] = React.useState('');
    const [fieldTwo, sFieldTwo] = React.useState('');
    const [submitted, setSubmitted] = React.useState(false)

    const selectVals = ["Image non conforme", "Son non conforme", "Bug sur un exercice", "Bug sur l'interface", "Autre"]

    const handleChange = event => {
        setType(event.target.value);
    }

    const handleSubmit = e => {
      e.preventDefault()
      setSubmitted(true)
      SendBugReport(selectVals[type],fieldOne,fieldTwo)
        .catch(e => {
          const sendAlert = msg => store.dispatch({
              type: 'TRIGGER',
              message: msg,
              severity: 'error'
          })

          setSubmitted(false)
          if(!e.response)
            return sendAlert('No server response')
          return sendAlert(e.response.data.errors)
        })
    }

    let user  = store.getState().Reducer.log_info
    return (
    <Container maxWidth="md" style={{padding:'8px'}}>
        <Card style={{display: 'flex', flexDirection: 'column' }} >
          <form onSubmit={handleSubmit}>
                <CardContent style={{ flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                <Select
                    value={type}
                    onChange={handleChange}
                >
                  {selectVals.map((val, idx) => <MenuItem value={idx}> {val} </MenuItem>)}
                </Select>
                <Render type={type} FieldOne={sFieldOne} FieldTwo={sFieldTwo} />
            </CardContent>
            <CardActions style = {{display: "flex", justifyContent: "center"}} >
                <SubmitButton
                    submitted={submitted}
                    endIcon={<SendIcon />}
                    variant='contained' color='primary'>Envoyer
                </SubmitButton>
            </CardActions>
          </form>
        </Card>
      </Container>
       
    )

}

export default withRouter(ReportBug);
