import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';


const Progress = ({progress: [progress, setProgress], time}) => {
  const ms = time * 1000

  React.useEffect(() => {
    let Pass = ((oldProgress) => {
      if (oldProgress <= 1.)
        oldProgress = oldProgress + 1 / (ms / 10)
      if (oldProgress >= 1.) {
        clearInterval(timer)
      }
      return (oldProgress);
    })

    function tick() {
      setProgress(oldProgress => (Pass(oldProgress)));
    }

    const timer = setInterval(tick, 10);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div style={{position: 'relative', width: '64px', height: '64px', borderRadius: '50%', textAlign: 'center'}}>
      <CircularProgress size={68} style={{position: 'absolute', top: -3, left: -3}} variant="determinate" value={progress * 100} />
      <div style={{textAlign: 'center', display: 'flex', alignItems: 'center', height: '100%'}}>
        <Typography style={{margin: 'auto'}} variant='h5'> {(time + 1) - Math.ceil(progress * time)} </Typography>
      </div>
    </div>
  )
}

export default function Wait({children, time}){

  const progress = React.useState(0);

  if(progress[0] >= 1.) {
    return children
  }
  return <Progress progress={progress} time={time}/>
}
