import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import ButtonBase from '@material-ui/core/ButtonBase';


export default function Word(props) {
  return (
    <Grid container spacing={1}>
    {Array.prototype.map.call(props.word, (letter, idx) => (
      <Grid item key={idx}>  
       <Paper elevation={1} style={{width: 50, height: 65, padding: '1'}}> 
         <Typography variant='h2'> {letter} </Typography>
       </Paper>
      </Grid>
    ))}
    </Grid>
  )
}

export function WordClick(props) {
  return (
    <Grid container spacing={1}>

    {Array.prototype.map.call(props.word, (letter, idx) => (
      <Grid item>  
        <ButtonBase  key={idx} onClick={()=>props.clickEvent(letter,idx)}>
       <Paper elevation={1} style={{width: 50, height: 65, padding: '1'}}> 
         <Typography variant='h2'> {letter} </Typography>
       </Paper>
       </ButtonBase>
      </Grid>
    ))}
    </Grid>
  )
}
