import React, { Component} from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import IconButton from '@material-ui/core/IconButton';

import GetAppIcon from '@material-ui/icons/GetApp';

import axios from 'axios'

import {connect} from 'react-redux'
import MapStateToPorps_test from './reducer/reducer'
import Store from './reducer/store'
import { withRouter } from 'react-router-dom'
import SubmitButton from './SubmitButton'

import history from './history'

import { login } from './userRequests'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignIn(props) {
  const classes = useStyles();
  const [email, setEmail] = React.useState(false) 
  const [password, setPassword] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [error, setError] = React.useState(0)

  const handleSubmit = (e) => {
    setSubmitted(true)
    const loginPromise = login(email, password)
    loginPromise.catch(err => {
      setSubmitted(false)
      const status = err.response ? err.response.status : "Can't connect"
      setError(status)
      Store.dispatch({
        type: 'TRIGGER',
        message: `Can't login: ${status}`,
        severity: 'error'
      })
    })
    loginPromise.then(() => history.push('/'))
    e.preventDefault()
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            error={error !== 0}
            fullWidth
            id="email"
            label="Email Address"
            autoFocus
            onChange={(value) => { setEmail(value.currentTarget.value) }}

          />
          <TextField
            margin="normal"
            required
            error={error !== 0}
            fullWidth
            label="Password"
            type="password"
            id="password"
            onChange={(value) => { setPassword(value.currentTarget.value) }}
          />
          <Grid item xs>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
          </Grid>
            <SubmitButton
              fullWidth
              variant="contained"
              color="primary"
              submitted={submitted}
            > Sign In </SubmitButton>
        </form>
      </div>
    </Container>
  );
}

export default  withRouter( connect(MapStateToPorps_test)(SignIn));

// export default HomePage
