import React, { Component } from 'react';

import { connect } from 'react-redux'
import MapStateToPorps_test from './reducer/reducer'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SendIcon from '@material-ui/icons/Send';
import ListIcon from '@material-ui/icons/ListAlt'
import { withRouter } from 'react-router-dom'

import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

import { capitalizeFirstLetter, accessWord } from './utils'

import store from './reducer/store'

export const InsetList = (props) => {

  const reducer = store.getState().Reducer
  const trained_words = reducer.SelectedPatient.trained_words

  const getFeedbackIcon = word => {
    if (!trained_words || !trained_words[word.mot])
      return

    switch (trained_words[word.mot].feedback) {
      case 0: return <SentimentVeryDissatisfiedIcon style={{color: 'red'}} />;
      case 1: return <SentimentVerySatisfiedIcon style={{color: 'green'}} />;
      case 2: return <SentimentSatisfiedIcon style={{color: 'orange'}} />;
    }

  }

  const HoverableItem = ({wordIdx}) => {
    const word = accessWord(wordIdx, reducer)
    const FeedbackIcon = getFeedbackIcon(word)

    return (
      <ListItem
        button
        onClick={() => {store.dispatch({type: 'ADD_LST_OUT_REMOV', word: word})}}
      >
        <Grid container>
          <Grid item xs>
            <ListItemText primary={capitalizeFirstLetter(word.mot)} />
          </Grid>
          {FeedbackIcon && <Grid item style={{display: 'flex', alignItems: 'center'}}> {FeedbackIcon} </Grid>}
        </Grid>
      </ListItem>
    )
  }
  if (props.list && props.list.length) {
    return (
      <List component="nav" style={{width: '100%'}}>
        {props.list.map(wordIdx => <HoverableItem key={wordIdx} wordIdx={wordIdx}/>)}
      </List>
    )
  }
  return (
    <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <ListIcon style={{ fontSize: "5rem"}} color='disabled'/>
    </div>
  )

}


export function SendButton() {
    return (
        <Button
            variant="contained"
            color="primary"
            // className={classes.button}
            endIcon={<SendIcon />}
        >
            Send
      </Button>
    )

}

function DText(props) {
    return (
        <Typography variant="h6" noWrap>
            {props.text}
        </Typography>
    )
}

class LstOutDisplay extends Component {
    render() {
        return (
            <Grid container justify="center">
                <div>
                    <DText text={"List des mots Sélectioner"} />
                    <InsetList list={this.props.Reducer.list_out} />
                    <SendButton />
                </div>
            </Grid>
        );
    }
}
export default withRouter(connect(MapStateToPorps_test)(LstOutDisplay));
