
import React from 'react';

import {connect} from 'react-redux'

import store from './reducer/store'

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import ThrashIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/GetApp';

import { FixedSizeGrid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { capitalizeFirstLetter } from './utils'

import SearchBar from './SearchBar'

const WordList = ({words, selectedIdx, setSelectedIdx}) => {

  const filterAll = () => true
  const filterWith = word => word.category !== undefined
  const filterWithout = word => word.category === undefined

  const [search, setSearch] = React.useState("")
  const [categoryFilter, setCategoryFilter] = React.useState({f: filterAll})

  const filteredWords = words.filter((e) => categoryFilter.f(e) && e.mot.includes(search))

  const handleRadioChange = event => {
    const v = event.target.value
    if (v === 'all') setCategoryFilter({f: filterAll});
    else if (v === 'with') setCategoryFilter({f: filterWith});
    else if (v === 'without') setCategoryFilter({f: filterWithout});
  }

  const CellRender = ({rowIndex, style}) => {
    const word = filteredWords[rowIndex]
    const customStyle = {...style}
    if (selectedIdx === word.id)
      customStyle.backgroundColor = 'rgb(0, 0, 0, 0.15)'
    return (
      <ListItem
        button={selectedIdx !== word.id}
        onClick={() => setSelectedIdx(word.id)}
        style={customStyle}
        key={word.mot}
      >
        <ListItemText primary={capitalizeFirstLetter(word.mot)}/>
      </ListItem>
    )
  }

  return (
    <Grid container direction='column' spacing={1} style={{padding: '8px', height: '600px'}}>
      <Grid item>
        <SearchBar
          onChange={(v) => setSearch(v)}
          onRequestSearch= {() => {}}
          placeholder='Recherche de mot'/>
      </Grid>
      <Grid item>
        <FormControl component="fieldset">
          <RadioGroup row name="filters" defaultValue="all" onChange={handleRadioChange}>
            <FormControlLabel
              value="all"
              control={<Radio color="primary" />}
              label="Tout"
              labelPlacement="end"
            />
            <FormControlLabel
              value="with"
              control={<Radio color="primary" />}
              label="Avec cat."
              labelPlacement="end"
            />
            <FormControlLabel
              value="without"
              control={<Radio color="primary" />}
              label="Sans cat."
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs>
        <AutoSizer>
          {({height, width}) => (
           <FixedSizeGrid
              className='Grid'
              height={height}
              width={width}
              columnCount={1}
              columnWidth={width - 16}
              rowCount={filteredWords.length}
              rowHeight={32}
            >
              {CellRender}
            </FixedSizeGrid>
          )}
        </AutoSizer>
      </Grid>
    </Grid>
  )
}

const WordInfo = ({word, categories}) => (
  <Grid container direction='column' style={{height: '600px'}}>
    <Grid item> <Typography align='center' variant='h5'> {capitalizeFirstLetter(word.mot)} </Typography> </Grid>
    <Grid item> <img alt={word.mot} style={{padding: '8px', width:'100%', maxHeight: '500px', borderRadius: '4px'}} src={`${process.env.PUBLIC_URL}/img/${word.mot}.jpg`}/> </Grid>
    <Grid item> <Typography align='center' variant='h4'> { word.category !== undefined ? capitalizeFirstLetter(categories[word.category]) : 'Aucune catégorie'} </Typography> </Grid>
  </Grid>
)

const NewCategory = () => {
  const [input, setInput] = React.useState("")

  return (
    <form onSubmit={e => {
      e.preventDefault()
      store.dispatch({type: 'ADD_CATEGORY', category: input})
      setInput('')
    }}>
    <Grid container spacing={1}>
      <Grid item xs>
        <TextField
          fullWidth
          variant='outlined'
          label='Nouvelle catégorie'
          value={input}
          onChange={event => setInput(event.target.value)}/>
      </Grid>
      <Grid item>
        <IconButton type='submit'> <AddIcon/> </IconButton>
      </Grid>
    </Grid>
    </form>
  )
}

const Categories = ({word, categories}) => (
  <Grid container direction='column' spacing={1} style={{padding: '8px', height: '600px'}}>
    <Grid item> <Typography variant='h5' align='center'> Catégories </Typography> </Grid>
    <Grid item xs>
      <List style={{overflow: 'hidden', overflowY: 'scroll', maxHeight: '460px'}}>
      <ListItem
         key={'none'}
         button={word.category !== undefined}
         onClick={() => store.dispatch({type: 'SET_CATEGORY', wordIdx: word.id, category: undefined})}
            style={word.category === undefined ? {backgroundColor: 'rgb(0, 0, 0, 0.15)'} : {}}>
            <ListItemText primary={'Sans catégorie'}/>
      </ListItem>
        {categories.map((category, idx) => (
          <ListItem
            key={category}
            button={word.category !== idx}
            onClick={() => store.dispatch({type: 'SET_CATEGORY', wordIdx: word.id, category: idx})}
            style={word.category === idx ? {backgroundColor: 'rgb(0, 0, 0, 0.15)'} : {}}>
            <ListItemText primary={capitalizeFirstLetter(category)}/>
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => store.dispatch({type: 'REMOVE_CATEGORY', category: idx})}>
                <ThrashIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Grid>
    <Grid item>
      <NewCategory/>
    </Grid>
  </Grid>
)

const exportJSON = () => {
  const element = document.createElement('a');
  const state = store.getState().Reducer
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify({ categories: state.categories, words: state.list})));
  element.setAttribute('download', 'word_base.json');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const CategoriesEditor = ({words, categories}) => {
  const [selectedIdx, setSelectedIdx] = React.useState(0);

  document.onkeypress = e => {
    const tag = e.target.tagName.toLowerCase();
    if ( tag === 'input' || tag === 'textarea')
      return
    e = e || window.event;

    const c = String.fromCharCode(e.keyCode).toLowerCase()
    if (c === 'n')
      setSelectedIdx(selectedIdx >= words.length - 1 ? 0 : selectedIdx + 1)
    else if (c === 'p')
      setSelectedIdx(selectedIdx <= 0 ? words.length - 1  : selectedIdx -1)
  }
  return (
    <Container style={{position: 'relative'}}>
      <Grid container spacing={1} style={{marginTop: '32px'}}>
          <Grid item xs> <Paper> <WordList words={words} selectedIdx={selectedIdx} setSelectedIdx={setSelectedIdx} /> </Paper> </Grid>
          <Grid item xs> <Paper> <WordInfo word={words[selectedIdx]} categories={categories}/> </Paper> </Grid>
          <Grid item xs> <Paper> <Categories word={words[selectedIdx]} categories={categories}/> </Paper> </Grid>
      </Grid>
      <Fab
        onClick={exportJSON}
        color='secondary'
        style={{position: 'absolute', top: 0, right: 0}}>
        <SaveIcon/>
      </Fab>
    </Container>
  )
}

export default connect(({Reducer}) => ({words: Reducer.list, categories: Reducer.categories}))(CategoriesEditor)
