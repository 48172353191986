import React from 'react';

// children module
import SignIn from './HomePage';
import LaySearchAndList from './layout/LaySearchAndList'
import {ExerciseList, ExerciseStart} from './ExerciseList';

import {Provider} from 'react-redux'
import store from './reducer/store'
import {Router, Route} from 'react-router-dom'
import history from './history'

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import MapStateToPorps_test from './reducer/reducer'
import {chpwd, SendModif, authenticate} from './userRequests'


function GetWords(list_id, props) {
  if (!list_id)
    return (null)
  let list_W = []
  list_id.forEach(wordF => {
    props.Reducer.list.forEach(word => {
      if (wordF === word.id) {
        list_W.push(word)
        // break;
      }
    });
  });
  if (list_W.length > 0)
    store.dispatch({type: 'REPLACE_LST_OUT', nlist: list_W})
  // console.log(list_W)
  return (list_W)

}


class Home extends React.Component {

  render() {
    // console.log(this.props)

    if (this.props.Reducer.log_info.log == false) {
      return (<SignIn />);
    }

    else {
      if (this.props.Reducer.log_info.type === 'patient') {
        console.log(this.props.Reducer.log_info)
        const patientType = this.props.Reducer.log_info.patientType 
        return (
          <Container
            maxWidth='xs'
            style={{height: '100%', display: 'flex', alignItems: 'center'}}>
            <Button
              fullWidth
              size='large'
              color='primary'
              variant='outlined'
              onClick={() => history.push(`/exercice/${patientType}/synced`)}>
              Commencer l'exercice
            </Button>
          </Container>
        )
      }
      else if (this.props.Reducer.log_info.type === 'practician')
        return (<LaySearchAndList />);
      return (<div></div>);
    }
  }
}
export default withRouter( connect(MapStateToPorps_test)(Home))
