import React from "react";


import { connect } from 'react-redux'
import MapStateToPorps_test from './reducer/reducer'
import { withRouter } from 'react-router-dom'

import InputBase from '@material-ui/core/InputBase';
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const SearchBar = ({onChange, onRequestSearch, placeholder, style, state, loading}) => {

  const defaulState = React.useState('')
  const [value, setValue] = state ? state : defaulState

  const handleChange = (input) => {
    setValue(input)
    onChange(input)
  }

  const requestSearch = () => {
    onRequestSearch(value)
    setValue('')
  }

  const RightWidget = () => {
    if (loading)
      return (<CircularProgress size={30} color="secondary" style={{marginRight: '8px'}}/>)
    if (value)
      return (
        <IconButton
          size="medium"
          aria-label="delete"
          onClick={() => handleChange('')}>
          <ClearIcon />
        </IconButton>
      )
    return (
      <IconButton
        size="medium"
        aria-label="search"
        onClick={requestSearch}>
        <SearchIcon />
      </IconButton>
    )
  }

  return (
    <Paper style={{...style, display: 'flex', alignItems: 'center'}} variant='outlined'>
     <InputBase
        disabled={loading}
        onChange={value => handleChange(value.currentTarget.value)}
        value={value}
        placeholder={placeholder}
        onKeyDown={(event) => {
          if (event.keyCode == 13) // ENTER is pressed
            requestSearch()
        }}
        style={{flexGrow: 1, margin: '8px', paddingLeft: '8px'}}/>
      <RightWidget />
    </Paper>
  )
}

export default withRouter(connect(MapStateToPorps_test)(SearchBar))
