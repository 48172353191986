
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

//    give title
// const useStyles = makeStyles({
//   avatar: {
//     backgroundColor: blue[100],
//     color: blue[600],
//   },
// });

export function PopupType1(props) {

    //   const classes = useStyles();
    const { onClose, selectedValue, open, title,buttonOneStyle , buttonTwoStyle} = props;

    const handleClose = () => {
        onClose(selectedValue[1]);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };
    return (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="PopupType1-title">{title}</DialogTitle>
            <DialogActions>
                    <Button
                        variant={(buttonOneStyle.variant) ?buttonOneStyle.variant : "contained"}
                        color={(buttonOneStyle.color) ? buttonOneStyle.color : "primary"}
                        style = {{...buttonOneStyle}}
                        onClick={() => handleListItemClick(selectedValue[0])}>OUI</Button>
                    <Button
                         variant={(buttonTwoStyle.variant) ? buttonTwoStyle.variant : "contained" }
                         color={(buttonTwoStyle.color) ? buttonTwoStyle.color : "secondary"}
                         style = {{buttonTwoStyle}}
                        onClick={() => handleListItemClick(selectedValue[1])}>NON</Button>
          </DialogActions>
        </Dialog>
    );
}

PopupType1.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.array.isRequired,
};



export function PopupType2(props) { // 3 boutten  and dont close on click on side

    
    const { onClose, selectedValue, open, title,buttonOneStyle , buttonTwoStyle,buttonThreeStyle,strOne,strTwo,strThree,FunCall} = props;
    const handleClose = () => {
        console.log("i will not remove my self like that")
        // onClose(selectedValue[1]);
    };


    const handleListItemClick = (value) => {
        // FunCall()
        onClose(value);
    };
        console.log(window.location.origin + '/afdm.pdf')
        
    return (
        //style ={{display :'flex', flexGrow :1,maxHeight:'-webkit-fill-available',maxWidth: 'fit-content'}}>
            <Dialog  fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title"  open={open}  style ={{width:"100%", height:"100%"}}  >

            {/* <DialogContent  style ={{width:"100%", height:"100%"}}> */}
            <iframe name= "my_pdf"src={window.location.origin + '/afdm.pdf'} width="100%" height="100%" ></iframe>
            {/* </DialogContent> */}
            <DialogTitle id="PopupType1-title">{title}</DialogTitle>

            <DialogActions>
            <Button
                         variant={(buttonOneStyle.variant) ? buttonOneStyle.variant : "contained" }
                         color={(buttonOneStyle.color) ? buttonOneStyle.color : "secondary"}
                         style = {{buttonOneStyle}}
                        onClick={() => handleListItemClick(selectedValue[0])}>{strOne}</Button>
                    <Button
                         variant={(buttonTwoStyle.variant) ? buttonTwoStyle.variant : "contained" }
                         color={(buttonTwoStyle.color) ? buttonTwoStyle.color : "secondary"}
                         style = {{buttonThreeStyle}}
                        onClick={() => handleListItemClick(selectedValue[1])}>{strTwo}</Button>
                    <Button
                         variant={(buttonThreeStyle.variant) ? buttonThreeStyle.variant : "contained" }
                         color={(buttonThreeStyle.color) ? buttonThreeStyle.color : "secondary"}
                         style = {{buttonThreeStyle}}
                        onClick={() => handleListItemClick(selectedValue[2])}>{strThree}</Button>
          </DialogActions>
        </Dialog>
        // <div/>
    );
}
