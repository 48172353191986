import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

export default({request, icons}) => {

  return (
    <Grid container spacing={2} justify='center'>
      {icons.map(({color, Icon}, idx) => (
        <Grid item key={idx}>
          <IconButton onClick={() => request(idx)} >
            <Icon style={{color: color, fontSize: 45}}/>
          </IconButton>
        </Grid>
      ))
      }
    </Grid>
  )
}
