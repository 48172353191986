import React from 'react';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

import SubmitButton from './SubmitButton'
import Store from './reducer/store'
import history from './history'
import { authenticate } from './userRequests'

const handleError = (resp) => {
  const sendAlert = msg => Store.dispatch({
    type: 'TRIGGER',
    message: msg,
    severity: 'error'
  })
  if (!resp) {
    sendAlert("No server response")
    return;
  }
  const err = resp.data.errors
  if (typeof err === 'string') {
    sendAlert(err)
    return;
  }
  return err
}

const UserForm = ({ defaults = {}, required = {}, name, onSubmit , patien = true}) => {
  const [state, setState] = React.useState({
    patientType: 'nonFluent',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    ...defaults
  });
  const [submitted, setSubmitted] = React.useState(false)
  const [errors, setErrors] = React.useState({})

  const handleChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    setState({
      ...state,
      [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)
    onSubmit(state)
     .then(async () => {
      await authenticate()
      history.goBack()
    }).catch((err) => {
      const errs = handleError(err.response)
      setSubmitted(false)
      if (errs)
        setErrors(errs)
      console.log(errs)
    })
  }

  return (
    <Container maxWidth="sm" style={{ padding: '8px' }}>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" justify="center" spacing={1}>
          <Grid item xs>
            <Grid container justify="center" spacing={1}>
              <Grid item xs>
                <TextField
                  required={required.firstName}
                  variant="outlined"
                  name='firstName'
                  label="Prénom"
                  style={{ width: '100%' }}
                  onChange={handleChange}
                  value={state.firstName} />
              </Grid>
              <Grid item xs>
                <TextField
                  required={required.lastName}
                  variant="outlined"
                  name='lastName'
                  label="Nom"
                  style={{ width: '100%' }}
                  onChange={handleChange}
                  value={state.lastName} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <TextField
              required={required.email}
              variant="outlined"
              name='email'
              type='email'
              label="Email"
              error={errors.email !== undefined}
              helperText={errors.email}
              style={{ width: '100%' }}
              onChange={handleChange}
              value={state.email} />
          </Grid>
          <Grid item xs>
            <TextField
              required={required.password}
              variant="outlined"
              name='password'
              label="Mot de passe"
              style={{ width: '100%' }}
              onChange={handleChange}
              value={state.password} />
          </Grid>
          <Grid item style={{ display: 'flex', justifyContent: 'right' }}>
            {patien ===  true ?
              <RadioGroup name="patientType" value={state.patientType} onChange={handleChange} row>
                <FormControlLabel
                  value="nonFluent"
                  control={<Radio color="secondary" />}
                  label="Non-Fluent"
                />
                <FormControlLabel
                  value="Fluent"
                  control={<Radio color="secondary" />}
                  label="Fluent"
                  // disabled
                />
              </RadioGroup>
              : ''}
            <SubmitButton
              variant="contained"
              size="large"
              color="primary"
              submitted={submitted}>{name}</SubmitButton>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default UserForm
