import React from 'react';

import { connect } from 'react-redux'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress'; 
import Typography from '@material-ui/core/Typography'

import IconButton from '@material-ui/core/IconButton';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

import Exercise from './exercise/NFluent'
import ExerciseFluent from'./exercise/Fluent'
import ExerciseDenoAlter from './exercise/DenoAlter'

import { makeStyles } from '@material-ui/core/styles';

import { accessWord } from './utils'
import store from './reducer/store'


// for redux and route
import history from './history'
import { Button } from '@material-ui/core';
import { authenticate, sendPatientFeedback, sendPracticianFeedback } from './userRequests'
import Feedbacks from './exercise/Feedbacks.js'
import Eval from './exercise/Eval'

// import ExerciseLayout from'./exercise/common/exerciseLayout'


const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  paper: {
    margin: '1%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '80vh',
    flexGrow: 1,
  },
  slideButton: {
    padding: theme.spacing(2),
    minWidth: '0%',
    height: '100%',
    borderRadius: '0%',
  },
  valid: {
    borderStyle: 'solid',
    borderWidth: '1px',
    width: '100px',
    height: '100px'
  }
}));


const ExerciseEnd = connect(({Reducer}) => ({evaluation: Reducer.AskFeedBack, userType: Reducer.log_info.type}))
(({wordIdx, evaluation, userType, nextExercise}) => {

  const Component = ({title, icons, request}) => {
    const [submitted, setSubmitted] = React.useState(false)
    const submit = (buttonId) => {
      setSubmitted(true)
      request(buttonId).finally(() => nextExercise())
    }
    return (
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Typography variant='h5'>{title}</Typography>
        </Grid>
        <Grid item>
          {!submitted ?  <Feedbacks request={submit} icons={icons} /> : <CircularProgress />}
        </Grid>
      </Grid>
    )
  }

  if (userType === 'practician' && evaluation) {
    const icons = [
      {color: 'green', Icon: ThumbUpIcon}, 
      {color: 'red', Icon: ThumbDownIcon}
    ]
    const request = (buttonId) => sendPracticianFeedback(wordIdx, [1, 0][buttonId])
    return <Component request={request} title='Le patient maîtrise le mot ?' icons={icons} />
  }

  if ((userType === 'practician' && !evaluation) || userType === undefined) {
    return <Button
      variant='outlined'
      endIcon={<ArrowForward />}
      color='primary'
      size='large'
      onClick={() => nextExercise()}> Mot Suivant </Button>
  }

  const icons = [
    {color: 'green', Icon: SentimentVerySatisfiedIcon},
    {color: 'orange', Icon: SentimentSatisfiedIcon},
    {color: 'red', Icon: SentimentVeryDissatisfiedIcon}
  ]
  const request = (buttonId) => sendPatientFeedback(wordIdx, [1, 2, 0][buttonId])
  return <Component request={request} title="Quel est votre ressenti sur l'exercice ?" icons={icons} />
})


export function ExerciseStart(props) {
  return (
    <Grid container direction='column' style={{ height: '100%', display: 'flex', padding: '10px 12px', justifyContent: 'center', alignContent: 'center' }}>
      {props.props.Reducer.list_out.length > 0 ? (
        <samp>
          <Grid item style={{ justifyContent: 'center', display: 'flex' }}>
            <Button variant="contained" color="primary" size='large' onClick={() => history.push('exercise/synced')}>Commencer</Button>
          </Grid>
        </samp>
      ) : (<Typography variant='h5'> pas dexo ! </Typography>)}
    </Grid>
  )
}

function ExerciseList({wordsIndex, exoType}) {


  const classes = useStyles();
  const Reducer = store.getState().Reducer
  let listOut = []
  if (wordsIndex)
    listOut = wordsIndex.map(idx => accessWord(idx, Reducer).mot)

  const [{step, idx}, setState] = React.useState({
    step: 0,
    idx: (Reducer.log_info.lastTrained + 1 <= listOut.length - 1 ? Reducer.log_info.lastTrained + 1 : 0)}
  );

  const handleNext = () => setState({idx, step: step + 1})
  const handleBack = () => setState({idx, step: step - 1})

  if (exoType !== 'nonFluent' && exoType !== 'Fluent' &&  exoType !== 'evaluation')
    return <div>invalid exercice type</div>

  const gohome = () => history.goBack();
  // if (exoType !== 'evaluation') {
  const maxSteps = (exoType === 'evaluation'?1 : (exoType === "nonFluent"?5:4)  )
    // const maxSteps = (exoType === "nonFluent"?5:4)}
 
  const exerciseCount = listOut.length
  const wordIndex = wordsIndex[idx]
  const nextExercise = () => {
    if (idx === exerciseCount - 1) {
      authenticate()
      gohome()
    }
    else setState({
        step: 0,
        idx: idx + 1
      })
  }

  if (listOut.length === 0) {
    gohome()
    return (<div>pas d'exercise</div>)
  }

  let progress = (step + (maxSteps * idx)) / (maxSteps * exerciseCount) * 100
  const userType = Reducer.log_info.log ? Reducer.log_info.type : 'patient'
  if ((userType !== 'patient' && !Reducer.AskFeedBack)
    && idx === exerciseCount - 1
    && step === maxSteps)
    gohome()

  return (
    <div className={classes.root}>
      <Container fixed style={{ minHeight: '50%', display: 'flex', flexDirection: 'column', minWidth: '95%' }}>
        <Paper className={classes.paper}>
          <Grid container justify='center' alignItems='stretch' style={{ flexWrap: 'nowrap', height: '100%' }}>
            <Grid item>
              <IconButton className={classes.slideButton} onClick={handleBack} disabled={step === 0}> <ArrowBack style={{ fontSize: 40 }} /> </IconButton>
            </Grid>
            <Grid item xs style={{justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ height: '100%', display: 'flex', flexWrap: 'nowrap', flexDirection: 'column' }}>
                <LinearProgress variant='determinate' value={Math.ceil(progress)} />
                <Paper elevation={0} style={{ display: 'flex', flexWrap: 'nowrap', justify: 'center', alignItems: 'center', flexGrow: 1 }} className={classes.paper}>
                  {step !== maxSteps ? (
                    <>
                      { exoType === "nonFluent" &&
                        <Exercise  step={step} word={listOut[idx]}  />
                      }
                      {exoType === "Fluent" &&
                        //  <h1>In DEV</h1>
                        <ExerciseFluent  step={step} word={listOut[idx]} wIdx={Reducer.list_out[idx]}  />
                      }
                      { exoType === "evaluation" &&
                        <Eval word={listOut[idx]}/>
                      }
                    </>
                    ) : (
                      <ExerciseEnd wordIdx={wordIndex} nextExercise={nextExercise}/>
                    )}
                </Paper>
              </div>
            </Grid>
            <Grid item>
              <IconButton className={classes.slideButton} onClick={handleNext} disabled={step === maxSteps}> <ArrowForward style={{ fontSize: 40 }} /> </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
}

export default ExerciseList

