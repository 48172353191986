import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import * as globalSound from '../sound'
// import SoundControl from './common/SoundControl'
import InputZone from './common/InputZone'
import Denomination from'./common/Denomination'
import {StepWithHeader} from'./common/header'
import { useTheme } from '@material-ui/core/styles';

// import Header from './common/header'
import { Howl } from 'howler'

import {rmaccent} from '../utils'



//déno orale sans aide et répétition et déno écrite
const instructions  = [['Dénommer le mot puis l\'écrire', false]]


function Eval(props) {

  const step = 0
  const img = {
    label: `${props.word}_img`,
    imgPath: process.env.PUBLIC_URL+'/img/'+props.word +'.jpg'  
  }
  //const sound = new Howl({ src: ['bateau.wav'] })
 const sound = new Howl({ src: process.env.PUBLIC_URL+'/2_son3/'+rmaccent(props.word) +'.wav'})



  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  if (instructions[step][1])
    globalSound.playSound(sound)

  return (
    <Grid container direction='column' justify='center' alignItems='center'>
        {step === 0 && (
          <Denomination {...props}  sound={sound} instructions={instructions[step][0]}  img={img} isXs={isXs}/>  
        )}
    </Grid>
  )
}

export default Eval;
