import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {frFR} from '@material-ui/core/locale';

import ExerciseList from './ExerciseList';
//import SlideButton from './SlideButton'
import SignIn from './HomePage';
import Typography from '@material-ui/core/Typography';

import Alert from './Alert'

import {Provider} from 'react-redux'
import store from './reducer/store'
import PersistentDrawerLeft from './SidePanel'
import {Router, Route, Redirect, Switch, useParams} from 'react-router-dom'
import history from './history'

import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import UserList from './UserList'
import LaySearchAndList from './layout/LaySearchAndList'
import CreatNewUserF from './Inscription'
import Home from './Home'
import ModifUser from './ModifUser'
import RaportBug from './ReportBug'
import ProfilePane from './ProfilePane'
import ExerciseDenoAlter from './exercise/DenoAlter'
import CategoriesEditor from './CategoriesEditor'

import CircularProgress from '@material-ui/core/CircularProgress'

const theme = createMuiTheme({
}, frFR);

class App extends React.Component {
  constructor(props) {
    super(props)
    const token = sessionStorage.getItem('jwtToken')
    if (token && token != '')
      this.state = {logging_in: true, token: token}
    else
      this.state = {logging_in: false}
  }

  componentDidMount() {
    if (this.state.token) {
      const rq = require('./userRequests')
      rq.authenticate(this.state.token).finally(() => this.setState({logging_in: false}))
    }
  }

  render() {

    const Centered = (props) => (
      <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100vh'}}>
        {props.children}
      </div>
    )

    const PrivateRoute = ({component: Component, authed, ...rest}) => (
      <Route
        {...rest}
        render={(props) => authed === true
          ? <Component {...props} />
          : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
      />
    )

    const NotFound = () => (
      <Centered>
        <Typography variant='h3'> Page not found </Typography>
      </Centered>
    )

    const Exercices = () => {
      const {type, synced} = useParams()

      const Reducer = store.getState().Reducer
      let list = Reducer.list_out

      if (synced) {
        const infos = Reducer.log_info
        list = infos ? infos.list : []
      }

      if (type === 'denomination_alternee') {
          const maxSteps = Reducer.list_out.length * 3 - 2
          if (maxSteps > 0)
            return <ExerciseDenoAlter lst_out={list} maxSteps={maxSteps} />
          else
            return <NotFound />
      }
      return (<ExerciseList wordsIndex={list} exoType={type}/>)
    }

    const Routes = connect(({Reducer}) => ({user: Reducer.SelectedPatient, logged: Reducer.log_info.log}))(({user, logged}) => (
      <Router history={history} >
        <Route path="/" component={PersistentDrawerLeft} />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/Selection_des_mots" component={LaySearchAndList} />
          <Route path="/modification_patient" user={user} component={ModifUser} />

          <Route path="/exercice/:type/:synced?">
            <Exercices />
          </Route>
          <Route path="/login" component={SignIn} />
          <PrivateRoute authed={logged} path="/nouveaux_patient" component={CreatNewUserF} />
          <PrivateRoute authed={logged} path="/rapport_bug" component={RaportBug} />
          <PrivateRoute authed={logged} path="/profile" component={ProfilePane} />
          <PrivateRoute authed={logged} path="/utilisateurs" component={UserList} />
          <PrivateRoute authed={logged} path="/categories" component={CategoriesEditor} />
          <PrivateRoute exact authed={logged} path="/" component={Home} />

          <Route component={NotFound} />
        </Switch>
      </Router>
    ))

    return (
      <>
        {!this.state.logging_in ? (
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
                <Routes />
              </div>
              <Alert />
            </ThemeProvider>
          </Provider >
        ) : (
            <Centered>
              <CircularProgress />
            </Centered>
          )
        }
      </>
    );
  }
}
export default withRouter(App)
// ReactDOM.render(<ExerciseList words={['chat']}/>, document.getElementById('root'));

// ========================================

ReactDOM.render(
  <App />,
  document.getElementById('root')
  );
