import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

const SubmitButton = ({submitted, children, ...props}) => {
  const classes = makeStyles(theme => ({
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }))()
  return (
    <div className={classes.wrapper}>
      <Button {...props} type='submit' disabled={submitted}> {children} </Button>
      {submitted && <CircularProgress size={24} color='secondary' className={classes.buttonProgress} />}
    </div>
  )
}
export default SubmitButton;
