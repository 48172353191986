import React from 'react';
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';


import Typography from '@material-ui/core/Typography'

// import Exercise from './exercise/NFluent'
// import ExerciseFluent from'./exercise/Fluent'

import { makeStyles } from '@material-ui/core/styles';

// import { accessWord } from './utils'
import store from '../reducer/store'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import * as globalSound from '../sound'
// import SoundControl from './common/SoundControl'
import InputZone from './common/InputZone'
import Denomination from'./common/Denomination'
import {StepWithHeader} from'./common/header'
import { useTheme } from '@material-ui/core/styles';

// import Header from './common/header'
import { Howl } from 'howler'

import {rmaccent} from '../utils'

// for redux and route
import history from '../history'
import { Button } from '@material-ui/core';
// import { authenticate, sendPatientFeedback, sendPracticianFeedback } from './userRequests'
// import Feedbacks from './exercise/Feedbacks.js'

const useStyles = makeStyles(theme => ({
    root: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh'
    },
    paper: {
      margin: '1%',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      maxHeight: '80vh',
      flexGrow: 1,
    },
    slideButton: {
      padding: theme.spacing(2),
      minWidth: '0%',
      height: '100%',
      borderRadius: '0%',
    },
    valid: {
      borderStyle: 'solid',
      borderWidth: '1px',
      width: '100px',
      height: '100px'
    }
  }));

function getInstruction(step)
{
    let i = 1;
    if (step <= 1) // obligation de repetition
    {
        return (['Répéter le mot', true])
    }
    // else{
        while (i < step)
        {
            if( i +3 == step) // introduction d'une nouvelle image
            {
                return (['Répéter le mot', true])
            }
            i = i+3
        }
        return (['Dénommer le mot', false])
    // }
}


function getIndex(step,arrayLen) //0,0,2,2,2,4,4,4,6,6,6,8,8 la diference entre lindex de limage a aficher et la step  sa ressemble a une suite matematique non ?
{
    let i = 1 
    let d = 2
    let rep = 0

    if (step <= 1)
        return(step)
    while (i < step)
    {
        i = i+1;



        if (rep ==3 )
        {
            rep = 0
            d = d +2
        }
        rep = rep+1

        console.log("d =" ,d," i = ",i, 'r = ' ,  i-d)
    }
    console.log("d =" ,d," i = ",i, 'r = ' ,  i-d)

    return(i - d)
}

 function  ExerciseDenoAlter(props) {
    const gohome = () => history.push('/');

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const {lst_out,maxSteps} = props
    console.log(maxSteps)
    
    console.log({lst_out,maxSteps})

    const [step, setState] = React.useState(0);
    const handleNext = () => setState(step + 1)
    const handleBack = () => setState(step - 1)
    const classes = useStyles();
    const  instructions = getInstruction(step)
    const index = getIndex(step,lst_out.length)
    console.log(step,index)
    const lst  = store.getState().Reducer.list
    console.log(lst[lst_out[index]])
    console.log(index,lst_out.length,index >= lst_out.length)
    if (index >= lst_out.length )
    {
        gohome()
        return(
            <div>lol</div>
        )
    }

    const sound = new Howl({ src: process.env.PUBLIC_URL+'/2_son3/'+rmaccent(lst[lst_out[index]].mot) +'.wav'})
    if (instructions[1])
     globalSound.playSound(sound)

    // else{
    const img = {
              label: `${lst[lst_out[index]].mot}_img`,
              imgPath: process.env.PUBLIC_URL+'/img/'+lst[lst_out[index]].mot +'.jpg'  //'../python/img_compres/'+ props.word +'.jpg'  // props.imgPath
            }
    return(
        <div className={classes.root}>
        <Container fixed style={{ minHeight: '50%', display: 'flex', flexDirection: 'column', minWidth: '95%' }}>
          <Paper className={classes.paper}>
            <Grid container justify='center' alignItems='stretch' style={{ flexWrap: 'nowrap', height: '100%' }}>
              <Grid item>
                <IconButton className={classes.slideButton} onClick={handleBack} disabled={step === 0}> <ArrowBack style={{ fontSize: 40 }} /> </IconButton>
              </Grid>
              <Grid item xs style={{justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ height: '100%', display: 'flex', flexWrap: 'nowrap', flexDirection: 'column' }}>
                  {/* <LinearProgress variant='determinate' value={Math.ceil(progress)} /> */}
                  <Paper elevation={0} style={{ display: 'flex', flexWrap: 'nowrap', justify: 'center', alignItems: 'center', flexGrow: 1 }} className={classes.paper}>


                    <StepWithHeader  {...props} sound={sound} instructions={instructions} img={img} isXs={isXs} > <Typography variant='h2'> {props.word} </Typography> </StepWithHeader>

                  </Paper>
              </div>
            </Grid>
            <Grid item>
              <IconButton className={classes.slideButton} onClick={handleNext} disabled={step === maxSteps}> <ArrowForward style={{ fontSize: 40 }} /> </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
    )
  }

  export default ExerciseDenoAlter;