import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import * as globalSound from '../sound'
// import SoundControl from './common/SoundControl'
import InputZone, { InputZoneClikable } from './common/InputZone'
import { useTheme } from '@material-ui/core/styles';
import {StepWithHeader} from'./common/header'

import Denomination from'./common/Denomination'
import Wait from './common/Wait'

import Header from './common/header'
import { Howl } from 'howler'
import {rmaccent} from '../utils'


const  instructions = [["Remettre les lettres dans l'ordre a l'aide de la souris", true], // string indication , song ?, timer
  ['Répéter puis écrire le mot en épelan en meme temps' , true],
  ['Écrire le mot de mémoire, puis le lire a haute voix', false],
  ["dénommer le mot par ecrit et puis a l'oral", false],]

function ExerciseFluent(props) {
  const img = {
    label: `${props.word}_img`,
    imgPath: process.env.PUBLIC_URL+'/img/'+props.word +'.jpg'  //'../python/img_compres/'+ props.word +'.jpg'  // props.imgPath
  }
 const sound = new Howl({ src: process.env.PUBLIC_URL+'/2_son3/'+rmaccent(props.word) +'.wav'})

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  if (instructions[props.step][1])
    globalSound.playSound(sound)
  return (
    <Grid container direction='column' justify='center' alignItems='center'>
        {props.step === 0 && (
         <StepWithHeader  {...props} sound={sound} instructions={instructions[props.step][0]} img={img} isXs={isXs} > <InputZoneClikable showModel  {...props} />  </StepWithHeader>
        )}
        {(props.step === 1) && ( 
         <StepWithHeader {...props}  sound={sound} instructions={instructions[props.step][0]} img={img} isXs={isXs} > <InputZone showModel {...props} />  </StepWithHeader>
        )}
       
      {(props.step === 2) && (
        <Wait time={10}> <StepWithHeader  {...props}  sound={sound} instructions={instructions[props.step][0]} img={img} isXs={isXs} > <InputZone  {...props}/> </StepWithHeader></Wait>
      )}
      {(props.step === 3) && (
        <Denomination {...props}  sound={sound} instructions={instructions[props.step][0]}  img={img} isXs={isXs}/>  

      )}
    </Grid>
  )
}

export default ExerciseFluent;
