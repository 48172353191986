import Store from './reducer/store'

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

export const accessWord = (id, reducer) => reducer.list[id]

export const cmpArrays = (array1, array2) => array1.length === array2.length && array1.every((value, index) => value === array2[index])

export const sendAlert = (msg, severity = 'success') => Store.dispatch({
  type: 'TRIGGER',
  message: msg,
  severity: severity
})

export function rmaccent (str) {
    var map = {
        '-' : '_',
        'a' : 'á|à|ã|â|À|Á|Ã|Â',
        'e' : 'é|è|ê|É|È|Ê',
        'i' : 'í|ì|î|Í|Ì|Î',
        'o' : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        'c' : 'ç|Ç',
        'n' : 'ñ|Ñ'
    };
    
    str = str.toLowerCase();
    
    for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern);
    };
  
    return str;
  };
