import React from 'react';
import Grid from '@material-ui/core/Grid';

import SoundControl from './SoundControl'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'



export const StepWithHeader = (props) => (
    <div>
    <Grid item>
      <Headers {...props} instructions={props.instructions} img={props.img} isXs={props.isXs} />
    </Grid>
    <Grid item  >
      {props.children}
    </Grid>
    </div>
  )

export default function Headers(props) {


    const useStyles = makeStyles(() => ({
        img: {
            height: 'auto',
            maxWidth: 400,
            maxHeight: '55vh',
            borderRadius: '4px'
        },
    }));




    const classes = useStyles()
    return (
        <span>
            <Grid container direction='column' justify='center' alignItems='center'>
                <Grid item>
                    <Typography variant='h6'> {props.instructions} </Typography>
                </Grid>
                <Grid item>
                    <Grid container justify='center' spacing={1}>
                        <Grid item>
                            <img alt={props.word} className={classes.img} src={props.img.imgPath} />
                        </Grid>
                        <Grid item>
                            <SoundControl showVolume={!props.isXs} sound={props.sound} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </span>
    );
}
