import React from 'react';
import Wait from './Wait'
import {StepWithHeader} from'./header'
import InputZone from './InputZone'




export default function Denomination(props){
    return(
      <span>
        {props.Wait ?(
        <Wait time={10}>
        <StepWithHeader  {...props} instructions={props.instructions} img={props.img} isXs={props.isXs} >
            <InputZone hideTemplate {...props} />
        </StepWithHeader>
   
        </Wait>
          ):
          <StepWithHeader  {...props} instructions={props.instructions} img={props.img} isXs={props.isXs} >
            <InputZone hideTemplate {...props} />
        </StepWithHeader>
          }
      </span>
    );
  }
