import React from "react";

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {connect} from 'react-redux'

import history from './history'

import HomeIcon from  '@material-ui/icons/Home';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BugIcon from '@material-ui/icons/BugReport';
import AccountIcon from '@material-ui/icons/AccountCircle';
import UserIcon from '@material-ui/icons/Person';
import SchoolIcon from '@material-ui/icons/School';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import CategoryIcon from '@material-ui/icons/Category';

import { logOut } from './log'
import {capitalizeFirstLetter} from './utils'

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        // marginBottom : 100,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        //marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(0),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        marginTop: 65, // marging pour eviter de disparaitre sous la bar bleu
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

 function PersistentDrawerLeft({patientType, isAdmin, isLogged}) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const isPractician = patientType === 'practician'

    const categories = [
      {title: 'accueil', location: '/', icon: <HomeIcon/>},
      {title: 'ajouter un utilisateur', location: '/nouveaux_patient', icon: <PersonAddIcon/>, allowed: isPractician},
      {title: 'utilisateurs', location: '/utilisateurs', icon: <UserIcon/>, allowed: isPractician},
      {title: 'signaler un bug', location: '/rapport_bug', icon: <BugIcon/>, allowed: isPractician},
      {title: 'entrainement personnalisé', location: '/selection_des_mots', icon: <SchoolIcon/>, allowed: !isPractician},
      {title: 'champs lexicaux', location: '/categories', icon: <CategoryIcon/>, allowed: isPractician && isAdmin},
    ].filter(c => c.allowed === undefined || c.allowed === true).map(c => (
      <ListItem
      button
      key={c.title}
      onClick={() => {
        history.push(c.location)
        setOpen(false)
      }}>
        <ListItemIcon> {c.icon} </ListItemIcon>
        <ListItemText
          primary={capitalizeFirstLetter(!isAdmin
            ? c.title.replace(/utilisateur/gi, 'patient')
            : c.title)}
        />
      </ListItem>
    ))

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar  display='flex'>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h5" noWrap style={ {flexGrow:1} }>
                        Au Fil Des Mots
                    </Typography>
                    { isLogged && (
                      <>
                      {window.location.pathname.includes('exercice') && patientType === 'practician' &&
                        <IconButton color="inherit" onClick={() => history.push('/')}>
                          <HomeIcon/>
                        </IconButton>
                      }
                      <IconButton color="inherit" onClick={() => history.push('/profile')}>
                        <AccountIcon/>
                      </IconButton>
                      <IconButton color="inherit" onClick={() => logOut() }>
                        <LogoutIcon/>
                      </IconButton>
                      </>
                    )}
                </Toolbar>

            </AppBar>

            <Drawer
                anchor="left" //left
                open={open}
                onClose={handleDrawerClose}
                className={classes.drawer}
                classes={{
                  paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <List> {categories} </List>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            />
        </div>
    );
}

export default connect(({Reducer}) => ({
  patientType: Reducer.log_info.type,
  isAdmin: Reducer.log_info.admin,
  isLogged: Reducer.log_info.log,
}))(PersistentDrawerLeft)
