import React from 'react';

import {connect} from 'react-redux'
import store from './reducer/store'

const AvailableFor = ({type, children}) => {
  const currentType = store.getState().Reducer.log_info.type;

  if (currentType === type)
    return children
  return <></>
}

export const AvailableForPatient = ({children}) => <AvailableFor type='patient'> {children} </AvailableFor>
export const AvailableForPractician = ({children}) => <AvailableFor type='practician'> {children} </AvailableFor>
export default AvailableFor
