import axios from 'axios'

import store from './reducer/store'
import history from './history'
import {accessWord} from './utils'
import { API } from './apiUrl'

// const API = 'http://localhost:52457/api'
//'https://afdm-mvp.herokuapp.com/api'

axios.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem('jwtToken');
  config.headers.Authorization = (token !== null) ? `Token ${token}` : ``;
  return config;
});

const receiveUserAndToken = (resp) => {
    // Store the new logged-in user informations
    store.dispatch({type: 'LOGIN', response: resp})
    // Insert the auth Token into the default http header
    const token = resp.data.user.token
    sessionStorage.setItem('jwtToken', token)
    // delete axios.defaults.headers.common['Authorization']
    // axios.defaults.headers.common['Authorization'] = 'Token ' + token
}

export const login = async (email, password) => {
  const body = {
    email: email,
    password: password
  }

  return axios.post(API + '/users/login', body).then(receiveUserAndToken)
}

export const authenticate = async (token) => {
  const url = `${API}/users/auth`

  if (!token)
    return axios.post(url).then(receiveUserAndToken)

  return axios({
    method: 'post',
    url: url,
    headers: {'Authorization': 'Token ' + token}
  }).then(receiveUserAndToken)
}

export const send_lst = async (patientID, Reducer) => {
  if(patientID == null || !patientID)
    alert('patient non selectioné')

  const body = {
    id: patientID,
    setFields: {
      list : Reducer.list_out,
      lastTrained : -1, // on remmet la save de position des exo au debut 
    }
  }

  const callback = () => {
    store.dispatch({type: 'SYNC_LIST'})
    history.push('/')
  }

  return axios.post(API + '/users/access', body).then(callback)
}


export const newUser = async ({firstName, lastName, email, password,patientType ,userType}) => {
  const name = {first : firstName, last: lastName}
  let user = {}
  if (userType === 'Practician')
     user = {email: email, password: password, name : name}
  else if (userType === 'Patient')
     user = {email: email, password: password, name : name}

  const body = {
      userType : userType ,
      user : user,
      patientType :patientType
  }

  const callback = (resp) => {
    console.log(resp)
  }
  return axios.post(API + '/users/new', body).then(callback)
}

export const GetUser = async (id) => {
  const body = { id : id } 
  const callback = (resp) => store.dispatch({type: 'SET_USER_INFO', response: resp ,id})

  return axios.post(API + '/users/access', body).then(callback)
}

export const SendModif = async (id, setFields) => {
  const body = {
    id: id,
    setFields : setFields
  }
  const callback = () => {
    console.log('Done')
  }

  return axios.post(API + '/users/access', body).then(callback)
}

export const chpwd = async (former, latest) => (axios.post(API + '/users/chpwd', {former, latest}))


export const SendBugReport = async (type, field1, field2 ) => {
  const body = {
    object : type,
    field1 : field1,
    field2 : field2,
  }
  const callback = () => {
    store.dispatch({
      type: 'TRIGGER',
      message: 'Bug soumit, Merci !',
      severity: 'success'
    })
    history.push('/')
  }
  return axios.post(API + '/bug/report', body).then(callback)
}

export const sendPatientFeedback = async (wordIdx, feedback) => {
  const reducer = store.getState().Reducer
  const currentFeedback = reducer.log_info.trained_words
  const word = accessWord(wordIdx, reducer).mot
  const body = {
    FeedBackOBj: {
      mot: word,
      objectMot: {
        id: wordIdx,
        feedback: feedback,
        learnt: currentFeedback && currentFeedback[word] ?
                currentFeedback[word].learnt
                : -1
      },
    },
    index: wordIdx
  }

  const callback = () => {
    console.log('Done')
  }

  return axios.post(API + '/users/Feadback', body).then(callback)
}


export const sendPracticianFeedback = async (wordIdx, learnt) => {
  const reducer = store.getState().Reducer
  const currentFeedback = reducer.SelectedPatient.trained_words
  const word = accessWord(wordIdx, reducer).mot
  const body = {
    FeedBackOBj: {
      mot: word,
      objectMot: {
        id: wordIdx,
        feedback: currentFeedback && currentFeedback[word] ?
                  currentFeedback[word].feedback
                : -1,
        learnt: learnt
      },
    },
    id: reducer.SelectedPatient.id
  }
  const callback = () => {
    store.dispatch({
      type: 'UPDATE_LISTS',
      wordIdx: wordIdx,
      feedback: learnt,
      oldFeedback: currentFeedback[word] ?
        currentFeedback[word].learnt : -1,
      newObject: body.FeedBackOBj
    })
  }

  return axios.post(API + '/users/FeadbackPro', body).then(callback)
  }

  export const deleteUser= async (patient) =>{
    const callback = () =>{
      authenticate()
      store.dispatch({type:'RESET_DEFAULT_SELECTEDPATIEN'})
    }

    return axios.post(API + '/users/Delete', patient).then(callback)
  }

