import React from 'react';
import {connect} from 'react-redux'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Tooltip from '@material-ui/core/Tooltip';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import SchoolIcon from '@material-ui/icons/School';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SearchBar from './SearchBar'
import {PopupType1} from './layout/Popup'

import {capitalizeFirstLetter} from './utils'
import {GetUser, deleteUser} from './userRequests'
import history from './history'
import store from './reducer/store'

const columns = [
  {id: 'last', label: 'Nom', minWidth: 100},
  {id: 'first', label: 'Prénom', minWidth: 100},
  {id: 'type', label: 'Type', minWidth: 100},
  {id: 'action', label: 'Actions', minWidth: 100, align: 'right'}
]

const Filters = ({isAdmin, filters, setFilters}) => {

  const handleChange = event => {
    setFilters({...filters, [event.target.name]: event.target.checked})
  }

  return (
    <Paper style={{padding: '8px'}}>
      <Grid container direction='column' justify='center' spacing={1}>
        <Grid item>
          <SearchBar
            placeholder="Rechercher"
            state={[filters.search, search => setFilters({...filters, search: search})]} onRequestSearch={() => {}}
            onChange={() => {}}
          />
        </Grid>
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox color='primary' onChange={handleChange} checked={filters.Fluent} name="Fluent" />}
              label="Fluents"
            />
            <FormControlLabel
              control={<Checkbox color='primary' onChange={handleChange} checked={filters.nonFluent} name="nonFluent" />}
              label="Non Fluents"
            />
            {isAdmin &&
              <FormControlLabel
                control={<Checkbox color='primary' onChange={handleChange} checked={filters.practician} name="practician" />}
                label="Praticiens"
              />
            }
          </FormGroup>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            color='primary'
            variant='outlined'
            onClick={() => history.push('/nouveaux_patient')}
          >Créer un utilisateur</Button>
        </Grid>
      </Grid>
    </Paper>
  )
}


const UserList = ({users}) => {

  const [filters, setFilters] = React.useState({
    search: '',
    practician: true,
    Fluent: true,
    nonFluent: true
  })
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedUserIdId, setSelectedUserId] = React.useState(null)

  const handleClosePopup = value => {
    console.log(value, selectedUserIdId)
    if (value === 1 && selectedUserIdId)
      deleteUser({id: selectedUserIdId})
    setSelectedUserId(null)
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isAdmin = store.getState().Reducer.log_info.admin

  const getType = ({user, patient}) => {
    if (isAdmin) {
      return user === 'patient' ? `Patient ${capitalizeFirstLetter(patient)}` : 'Praticien'
    }
    return `Patient ${capitalizeFirstLetter(patient)}`
  }

  const rows = users.filter(({name: {first, last}, type}) => (
    ((type.user == 'practician' && filters.practician) || (type.user == 'patient' && filters[type.patient]))
    && `${first}${last}`.includes(filters.search)
  )).map(({name: {first, last}, type, id}) => ({
    first: capitalizeFirstLetter(first),
    last: last.toUpperCase(),
    action: (
      <span>
        <Tooltip title='Sélectionner pour séance'>
          <span>
            <IconButton
              onClick={() => {GetUser(id).then(() => history.push('/'))}}
              disabled={type.user === 'practician'}
              style={{margin: '-12px 0 -12px'}}>
              <SchoolIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title='Éditer'>
          <span>
            <IconButton
              onClick={() => {GetUser(id).then(() => history.push('/modification_patient'))}}
              style={{margin: '-12px 0 -12px'}}>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title='Supprimer'>
          <span>
            <IconButton
              onClick={() => {setSelectedUserId(id)}}
              style={{margin: '-12px 0 -12px'}}>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </span>),
    type: getType(type)
  }))

  return (
    <Container style={{position: 'relative', height: '100%', paddingTop: '16px'}}>
      <Grid container spacing={3}>
        <Grid item>
          <Filters isAdmin={isAdmin} filters={filters} setFilters={filters => {
            if (page != 0) setPage(0)
            setFilters(filters)
          }} />
        </Grid>
        <Grid item xs>
          <Paper>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow key="caption">
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{minWidth: column.minWidth}}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage='Utilisateurs par page:'
            />
          </Paper>
        </Grid>
      </Grid>
      <PopupType1 onClose={handleClosePopup} selectedValue={[1, 0]} open={selectedUserIdId !== null} title="Supprimer l'utilisateur ?" buttonOneStyle={{variant: "contained", color: "secondary"}} buttonTwoStyle={{variant: "outlined", color: "primary"}} />
    </Container>

  );
}

export default connect(({Reducer}) => ({users: Reducer.log_info.users}))(UserList)
