import React from "react";


import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from "@material-ui/core/IconButton";
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import { capitalizeFirstLetter, cmpArrays, sendAlert } from '../utils'
import SearchBar from '../SearchBar'
import ExpandableButton from './ExpandableButton'
import { GetUser, deleteUser } from '../userRequests'

import { InsetList } from '../LstOutDisplay'
import { connect } from 'react-redux'
import MapStateToPorps_test from '../reducer/reducer'
import history from '../history'
import { PopupType1 } from './Popup'
import { send_lst } from '../userRequests'
import store from '../reducer/store'

import WordListImpl from './WordList.js'



const WordList = () => {
  return (
    <Card style={{ height: '100%', maxHeight: '100%' }}>
      <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flexGrow: 1 }}>
          <WordListImpl />
        </div>
      </CardContent>
    </Card>
  )
}

const SearchPatient = ({ users }) => {

  const inputState = React.useState('');
  const [_, setInput] = inputState
  const [foundUsers, setFoundUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false)

  const searchUsers = (value) => {
    if (!value) {
      setFoundUsers([])
    }
    else {
      const name = value.toLowerCase().replace(/\s/g, '')
      setFoundUsers(users.filter(user => `${user.name.first}${user.name.last}`.includes(name)))
    }
  }

  const selectUser = idx => {
    if (foundUsers.length <= idx) return;

    setLoading(true)
    GetUser(foundUsers[idx].id).finally(() => setLoading(false))
    setFoundUsers([])
    setInput('')
  }

  const FoundUsersList = () => (
    <List>
      {foundUsers.map((user, idx) => (
        <ListItem
          button
          onClick={() => selectUser(idx)}
          key={idx} >
          {`${capitalizeFirstLetter(user.name.first)} ${user.name.last.toUpperCase()}`}
        </ListItem>
      ))}
    </List>
  )

  return (
    <Paper variant='outlined' style={{ maxHeight: '25vh' }} >
      <Grid container direction='column'>
        <Grid item>
          <SearchBar
            onChange={searchUsers}
            placeholder="Rechercher un patient"
            onRequestSearch={() => selectUser(0)}
            state={inputState}
            loading={loading}
          />
        </Grid>
        {foundUsers.length != 0 && <Grid item> <FoundUsersList /> </Grid>}
      </Grid>
    </Paper>
  )
}

const PatientPane = ({ Reducer }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(["oui", "non"]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClosePopup = (value) => {
    setOpen(false);
    if (value == "oui")
      deleteUser(Reducer.SelectedPatient)
  };

  const isLogged = Reducer.log_info.log
  const isListEmpty = Reducer.list_out && Reducer.list_out.length === 0
  const isPractician = Reducer.log_info.log && Reducer.log_info.type === 'practician'

  const isSelectedPatient = Reducer.SelectedPatient.name.first
  const selectedPatientType = Reducer.SelectedPatient.patientType
  const fullname = `${capitalizeFirstLetter(Reducer.SelectedPatient.name.first)} ${capitalizeFirstLetter(Reducer.SelectedPatient.name.last)}`
  let ptStr = "Patient "
  const pt = Reducer.SelectedPatient.patientType
  if (!pt)
    ptStr = ptStr + 'Non fluent (unset)'
  else if (pt === 'Fluent')
    ptStr = ptStr + 'Fluent'
  else if (pt === 'nonFluent')
    ptStr = ptStr + 'Non Fluent'
  else
    ptStr = ptStr + 'type inconnu '

  const MoreMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <div>
        <PopupType1 onClose={(value) => handleClosePopup(value)} selectedValue={selectedValue} open={open} title={"Supprimer définitivement l'utilisateur ?"}
          buttonOneStyle={{ variant: "contained", color: "secondary" }} buttonTwoStyle={{ variant: "outlined", color: "primary" }} />

        <IconButton onClick={handleClick}> <MoreVertIcon /> </IconButton>
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => {
            store.dispatch({type: 'RESET_DEFAULT_SELECTEDPATIEN'})
            handleClose()
          }}>
            <ListItemIcon> <CloseIcon/> </ListItemIcon>
            <ListItemText primary="Désélectionner"/>
          </MenuItem>
          <MenuItem onClick={() => {
            history.push('/modification_patient')
            handleClose()
          }}>
            <ListItemIcon> <EditIcon/> </ListItemIcon>
            <ListItemText primary="Modifier"/>
          </MenuItem>

          <MenuItem style={{color: 'red'}} onClick={() => {
            handleClickOpen()
            handleClose()
          }}>
            <ListItemIcon style={{color: 'inherit'}}> <DeleteIcon/> </ListItemIcon>
            <ListItemText primary="Supprimer"/>
          </MenuItem>

        </Menu>
      </div>
    )
  }

  const ListControls = () => (
    <Grid container direction='column'>
      {(isPractician && isSelectedPatient) && (
        <Grid item>
          <Tooltip title="Envoyer la liste" placement='left'>
            <div>
              <IconButton
                disabled={isListEmpty || cmpArrays(Reducer.list_out, Reducer.sync_list)}
                onClick={() => {
                  send_lst(Reducer.SelectedPatient.id, Reducer)
                    .then(() => sendAlert("Liste envoyée"))
                    .catch(() => sendAlert("Impossible d'envoyer la liste", 'error'))
                }}
                size='medium'> <SendIcon /> </IconButton>
            </div>
          </Tooltip>
        </Grid>
      )}
      <Grid item>
        <Tooltip title="Vider la liste" placement='left'>
          <div>
            <IconButton
              disabled={isListEmpty}
              size='medium'
              onClick={() => store.dispatch({ type: 'REPLACE_LST_OUT', nlist: [] })} > <DeleteIcon /> </IconButton>
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  )


  return (
    <Card style={{ display: 'flex', flexDirection: 'column' }} >

      {isSelectedPatient &&
        <>
          <CardHeader
            action={<MoreMenu />}
            title={fullname}
            subheader={ptStr} />
          <Divider />
        </>
      }

      <CardContent style={{ flexGrow: 1, display: 'flex', maxHeight: '100%', overflow: 'auto', backgroundColor: 'rgb(250, 250, 250)' }} >
        <Grid item xs>
          <Grid container direction='row' style={{ height: '100%' }}>
            <Grid item>
              <ListControls />
            </Grid>
            <Divider orientation='vertical' style={{ marginLeft: '4px', marginRight: '4px' }} />
            <Grid item xs>
              <InsetList list={Reducer.list_out} />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {isLogged && !isPractician && (
          <Button
            variant='outlined'
            onClick={() => history.push(`/exercice/${Reducer.log_info.patientType}`)}
            color='primary'>commencer</Button>
        )}
        {isPractician && isSelectedPatient && (
          <ExpandableButton disabled={isListEmpty} options={[{
              title: 'Entrainer',
              onClick: () => {
                store.dispatch({ type: 'SET_EVAL_MODE', value: false })
                history.push(`/exercice/${selectedPatientType}`)
              }
            }, {
              title: "Évaluer",
              onClick: () => {
                store.dispatch({ type: 'SET_EVAL_MODE', value: true })
                history.push('/exercice/evaluation')
                // history.push(`/exercice/${selectedPatientType}`)
              },
            }, {
              title: "Dénomination Alternée",
              onClick: () => {
                store.dispatch({ type: 'SET_EVAL_MODE', value: false })
                history.push('/exercice/denomination_alternee')
              },
              disabled: Reducer.list_out.length < 3
            }]}/>
        )}
        { ((isPractician && !isSelectedPatient) || !isLogged) && (
          <ExpandableButton disabled={isListEmpty} options={[{
              title: 'Non-fluent',
              onClick: () => {
                store.dispatch({ type: 'SET_EVAL_MODE', value: false })
                history.push(`/exercice/nonFluent`)
              }
            }, {
              title: "Fluent",
              onClick: () => {
                store.dispatch({ type: 'SET_EVAL_MODE', value: false })
                history.push(`/exercice/Fluent`)
              },
            }, {
              title: "Dénomination Alternée",
              onClick: () => {
                store.dispatch({ type: 'SET_EVAL_MODE', value: false })
                history.push('/exercice/denomination_alternee')
              },
              disabled: Reducer.list_out.length < 3
            }]}/>
        )}
      </CardActions>
    </Card>
  )
}

const LaySearchAndList = (props) => {

  return (
    <Container maxWidth="xl" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container style={{ flexGrow: 1 }}>
        <Grid item xs style={{ padding: '24px 4% 24px 4%' }}>
          <WordList />
        </Grid>
        <Grid item xs={4} style={{ padding: '24px 4% 24px 4%' }}>
          <Grid container direction='column' style={{ height: '100%' }}>
            {(props.Reducer.log_info.log && props.Reducer.log_info.type === 'practician') && (
              <Grid item style={{ paddingBottom: '10%', maxWidth: '100%' }}>
                <SearchPatient users={props.Reducer.log_info.users} />
              </Grid>
            )}
            <Grid item xs>
              <PatientPane Reducer={props.Reducer} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default connect(MapStateToPorps_test)(LaySearchAndList)
