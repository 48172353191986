import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import Edit from '@material-ui/icons/Edit';
import ButtonBase from '@material-ui/core/ButtonBase';
import Word ,{WordClick} from './Word'
import {SylableMulty} from './SoundControl'
import {rmaccent} from '../../utils'


function randomiseWorld(word){
  let tabIndex = Array( word.length -1)
  let rWordl = ''
  for (let i = 0; i < word.length; i++) {
    tabIndex[i]=i
  }
  let i = 0
  while(tabIndex.length !== 0) {
   i =  Math.floor(Math.random() * tabIndex.length);
   i = tabIndex[i]
   rWordl = rWordl + word[i]
   let index = tabIndex.indexOf(i);
    if (index > -1) {
      tabIndex.splice(index, 1);
      }
  }
  return(rWordl)
}

export function InputZoneClikable(props) {
  const [input, setInput] = React.useState("")
  const [RandomWorld, setRandomWorld] = React.useState(randomiseWorld(props.word))
   const clickEvent = (l,idx)  =>{
      if (props.word[input.length] === l)
      {
        setInput(input + l)
        setRandomWorld( (RandomWorld.substring(0, idx) + ' ' + RandomWorld.substring(idx + 1)))
      }
    }
  return (
    <Grid container direction='column' spacing={1} style={{alignContent:'center'}}>
      {props.showModel && (
        <Grid item xs >
         <WordClick word={RandomWorld} clickEvent = {clickEvent}/>
        </Grid>
      )}
      {props.word !== input &&(

      <Grid item xs>
        <Word word={props.hideTemplate ? input : input + "_".repeat(props.word.length - input.length)} />
        {(props.hideTemplate && !input) && (
          <Typography variant='h2' style={{userSelect: 'none'}}>…<Edit fontSize="large" /></Typography>
        )}
      </Grid>
      )}
      {props.word === input &&(
      <Grid item xs>
        
        <SylableMulty word ={props.word} wIdx={props.wIdx}/>
      </Grid>

      )}
    </Grid>
  )
}





export default function InputZone(props) {
  const [input, setInput] = React.useState("")

  document.onkeypress = e => {
    e = e || window.event;
    let  c = String.fromCharCode(e.keyCode).toLowerCase()
    const noAxent =  rmaccent(props.word)
    if ( noAxent[input.length] === c || c === props.word[input.length])
    {
      c = props.word[input.length] // pour afficher avec laccent meme si letre seul 
      setInput(input + c)
    }
  }

  return (
    <Grid container direction='column' spacing={1} style={{alignContent:'center'}}>
      {props.showModel && (
        <Grid item xs >
          <Word word={props.word} />
        </Grid>
      )}
      <Grid item xs>
        <Word word={props.hideTemplate ? input : input + "_".repeat(props.word.length - input.length)} />
        {(props.hideTemplate && !input) && (
          <Typography variant='h2' style={{userSelect: 'none'}}>…<Edit fontSize="large" /></Typography>
        )}
      </Grid>
    </Grid>
  )
}
