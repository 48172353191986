import React from 'react';

import {connect} from 'react-redux'
import store from '../reducer/store'

import AutoSizer from "react-virtualized-auto-sizer";

import Tabs from '@material-ui/core/Tabs';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Tab from '@material-ui/core/Tab';
import ListItem from '@material-ui/core/ListItem'
import MuiGrid from '@material-ui/core/Grid'
import Slider from '@material-ui/core/Slider';
import ListItemText from '@material-ui/core/ListItemText';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import DoneIcon from '@material-ui/icons/Done';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Badge from '@material-ui/core/Badge'
import ListIcon from '@material-ui/icons/ListAlt'
import {AvailableForPractician} from '../AvailableFor'

import SearchBar from '../SearchBar'
import { FixedSizeGrid as Grid } from "react-window";
import { capitalizeFirstLetter, accessWord } from '../utils'

const RenderList = connect(({Reducer}) => ({listOut: Reducer.list_out}))(({list, listOut}) => {
  const state = store.getState().Reducer
  const columnCount = Math.min(list.length, 3)
  const visibleRowCount = 10;

  const CellRender = ({columnIndex, rowIndex, style}) => {
    const key = (rowIndex * columnCount) + columnIndex

    if (key < list.length) {

      const wordIdx = list[key]
      const word = accessWord(wordIdx, state)
      return (
        // visibility: hidden; can be use for remove word in list
        <ListItem
          button
          onClick={() => {store.dispatch({type: 'ADD_LST_OUT_REMOV', word: word})}}
          style={style}
          key={key}
        >
          <ListItemText style={listOut.includes(wordIdx) ? {color: 'rgb(0, 0, 0, 0.5)'} : {}} primary={capitalizeFirstLetter(word.mot)} />
        </ListItem>
      );
    }
    return (<div style={style}></div>)
  }

  if (!list.length)
    return (
      <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <ListIcon style={{ fontSize: "5rem"}} color='disabled'/>
      </div>)

  return (
    <AutoSizer>
      {({height, width}) => {
        return (
          <Grid
            className="Grid"
            columnCount={columnCount}
            columnWidth={width / columnCount - 5}
            height={height}
            rowCount={Math.ceil(list.length / columnCount)}
            rowHeight={height / visibleRowCount}
            width={width}
          >
            {CellRender}
          </Grid>
        )
      }}
    </AutoSizer>
  );
})

const mapToList = (listName) => ({Reducer}) => ({list: Reducer.display_lists[listName]})

const mapBadge = (listName) => ({Reducer}) => ({
  show: Reducer.search !== '' && Reducer.SelectedPatient.id !== null,
  list: Reducer.display_lists[listName]
})

const BadgedIcon = ({icon, list, show}) => {
  const val = list.length
  return (<Badge color="secondary" badgeContent={val} max={9} invisible={!val || !show}>
    {icon}
  </Badge>)
}

const UntrainedBadge = connect(mapBadge("untrained"))(BadgedIcon)
const LearningBadge = connect(mapBadge("learning"))(BadgedIcon)
const LearntBadge = connect(mapBadge("learnt"))(BadgedIcon)

const Categories = connect(({Reducer}) => ({isPatientSelected: Reducer.SelectedPatient.id !== null}))(({isPatientSelected}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <MuiGrid container direction='column' style={{height: '100%'}}>
      <AvailableForPractician>
        <MuiGrid item>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            variant='fullWidth'
          >
            <Tab icon={<UntrainedBadge icon={<MoreIcon/>} />} label="Non Travaillés" />
            <Tab disabled={!isPatientSelected} icon={<LearningBadge icon={<TrendingUpIcon />}/>} label="En Progrès" />
            <Tab disabled={!isPatientSelected} icon={<LearntBadge icon={<DoneIcon />} />} label="Acquis" />
          </Tabs>
        </MuiGrid>
      </AvailableForPractician>
      <MuiGrid item xs>
        
        <UntrainedList hidden={value !== 0}/>
        <LearningList hidden={value !== 1} />
        <LearntList hidden={value !== 2} />
      </MuiGrid>
    </MuiGrid>
  );
})

const HideableList = ({hidden, list}) => (
  <div hidden={hidden} style={{height: '100%'}}>
    <RenderList list={list}/>
  </div>
)

const UntrainedList = connect(mapToList("untrained"))(HideableList)
const LearningList = connect(mapToList("learning"))(HideableList)
const LearntList = connect(mapToList("learnt"))(HideableList)

const Widgets = connect(({Reducer}) => ({dynamicSelectValue: Reducer.dynamic_select}))(({dynamicSelectValue}) => {

  const [value, setValue] = React.useState([1, 4]);

  const handleChange = (_, newValue) => {
    if (value[0] !== newValue[0] || value[1] !== newValue[1]) {
      setValue(newValue);
      store.dispatch({type: 'CHANGE_FILTER', syllables: newValue})
    }
  };

  const marks = [1, 2, 3, 4].map((v, idx) => ({
    value: v,
    label: idx == 3 ? '4+' : `${v}`
  }))
  return (
    <MuiGrid container justify='center' spacing={4}>
      <AvailableForPractician>
        <MuiGrid item xs>
          <Tooltip title="Sélection dynamique" placement='top'>
            <Switch
              checked={dynamicSelectValue}
              onChange={() => store.dispatch({type: 'TOGGLE_DYNAMIC_SELECT'})}/>
          </Tooltip>
        </MuiGrid>
        <MuiGrid item xs style={{marginLeft: '8px'}}>
          <Tooltip title="Filtre à syllabes" placement='top'>
            <Slider style={{width: '45%'}}
              value={value}
              onChange={handleChange}
              getAriaLabel={() => {}}
              step={1}
              marks={marks}
              min={marks[0].value}
              max={marks[marks.length - 1].value}
            />
          </Tooltip>
        </MuiGrid>
      </AvailableForPractician>
      <MuiGrid item xs={4}>
        <SearchBar
          onChange={val => store.dispatch({type: 'CHANGE_FILTER', search: val})}
          onRequestSearch={() => {}}
          placeholder="Rechercher un mot"
        />
      </MuiGrid>
    </MuiGrid>
  )
})

export default () => {
    return (
      <MuiGrid container spacing={2} direction='column' style={{height: '100%'}}>
        <MuiGrid item>
          <Widgets />
        </MuiGrid>
        <MuiGrid item xs>
          <Categories />
        </MuiGrid>
      </MuiGrid>
    )
}
