
const defaultState = {
  message: null,
  severity: 'info',
  display: false,
}

export default function Alert(state = defaultState, action) {
  const {type, message, severity} = action
  switch (type) {
    case 'TRIGGER':
      return {
          message: message,
          severity: severity ? severity : defaultState.severity,
          display: true
      }
    case 'CLEAR':
      return {
        ...state,
        display: false
      }
  }
  return state
}
