import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import * as globalSound from '../sound'
// import SoundControl from './common/SoundControl'
import InputZone from './common/InputZone'
import Denomination from'./common/Denomination'
import {StepWithHeader} from'./common/header'
import { useTheme } from '@material-ui/core/styles';

// import Header from './common/header'
import { Howl } from 'howler'

import {rmaccent} from '../utils'




const instructions  = [['Répéter le mot', true], // string indication , song ?, timer
['Copier le mot', true],
['Écrire le mot', false],
['Répéter le mot', false],
['Dénommer le mot puis l\'écrire', false]]


function Exercise(props) {
  const img = {
    label: `${props.word}_img`,
    imgPath: process.env.PUBLIC_URL+'/img/'+props.word +'.jpg'  //'../python/img_compres/'+ props.word +'.jpg'  // props.imgPath
  }
  //const sound = new Howl({ src: ['bateau.wav'] })
 const sound = new Howl({ src: process.env.PUBLIC_URL+'/2_son3/'+rmaccent(props.word) +'.wav'})



  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  if (instructions[props.step][1])
    globalSound.playSound(sound)

  return (
    <Grid container direction='column' justify='center' alignItems='center'>
        {props.step === 0 && (
         <StepWithHeader  {...props} sound={sound} instructions={instructions[props.step][0]} img={img} isXs={isXs} > <Typography variant='h2'> {props.word} </Typography> </StepWithHeader>
        )}
        {(props.step === 1) && ( 
         <StepWithHeader {...props}  sound={sound} instructions={instructions[props.step][0]} img={img} isXs={isXs} > <InputZone showModel {...props} />  </StepWithHeader>
        )}
        {(props.step === 2) && ( 
         <StepWithHeader  {...props}  sound={sound} instructions={instructions[props.step][0]} img={img} isXs={isXs}> <InputZone {...props}/>  </StepWithHeader>

        )}
         {props.step === 3 && (
         <StepWithHeader  {...props}  sound={sound} instructions={instructions[props.step][0]} img={img} isXs={isXs} > <Typography variant='h2'> {props.word} </Typography> </StepWithHeader>
        )}
      {(props.step === 4) && (
       <Denomination {...props} Wait={true} sound={sound} instructions={instructions[props.step][0]}  img={img} isXs={isXs}/>  

      )}
    </Grid>
  )
}

export default Exercise;
