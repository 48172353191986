import React from 'react';

import UserForm from './UserForm'
import {newUser} from './userRequests'
import {connect} from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import history from './history'
import {PopupType2} from './layout/Popup'


function CallPopup(props)
{
  const {handleClosePopup,openPop} = props
  return (
    <div  className = "popupType2" style = {{display: 'flex',flexGrow:1, height:'inherit'}}>

  <PopupType2 onClose={handleClosePopup} selectedValue={[0, 2,1]} open={openPop} title="Merci de faire signer ce document au patient avant de créer un compte"
          buttonOneStyle={{variant: "outlined", color: "primary"}}
          buttonTwoStyle={{variant: "contained", color: "secondary"}}
          buttonThreeStyle={{variant: "outlined", color: "primary"}}
          strOne = "Imprimer"
          strTwo = "Refuser"
          strThree = "Accepter"></PopupType2>

          <UserForm
            name="Nouveau Patient"
            required={{ firstName: true, lastName: true, email: true, password: true }}
            onSubmit={(p) => newUser({...p,userType: 'Patient'})} />
        </div>
  )
  
}

async function print()
{
  const x = window.frames["my_pdf"]
  x.window.print()
  console.log(x)
}

const NewUser = ({isAdmin}) => {
  const [createAdmin, setCreateAdmin] = React.useState(false)
  const  [openPop,popModif] =  React.useState(true)
  const handleClosePopup = value => {
   switch (value) {
     case 0:
       print()
       break;
    
      case 1 :
        popModif(false)
         break;


      case 2 :
        history.push('/')
       break;

     default:
       break;
   }
  }

  const Wrapper = ({form}) => (
    <div style={{marginTop: '32px'}}>
      <Grid container direction='column'>
        {isAdmin &&
          <Grid item>
            <FormControlLabel
              style={{display: "flex", justifyContent: "center"}}
              control={<Checkbox checked={createAdmin}
                value={false}
                onChange={(event) => setCreateAdmin(event.target.checked)} />}
              label="Ajouter un compte praticien"
            />
          </Grid>
        }
        <Grid item>
          {form}
        </Grid>
      </Grid>
    </div>
  )

  return (
    <>

    <Wrapper form={
      createAdmin === true ?
        <UserForm
          name="Nouveau Praticien"
          required={{firstName: true, lastName: true, email: true, password: true}}
          patien={false}
          onSubmit={(p) => newUser({...p, userType: 'Practician'})} />
        :
        <CallPopup handleClosePopup = {handleClosePopup} openPop ={openPop}/>
    } />
    </>

  )
}

export default connect(({Reducer}) => ({isAdmin: Reducer.log_info.admin}))(NewUser)


