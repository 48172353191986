import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Reducer from './reducer/Alert'
import Store from './reducer/store'
import {connect} from 'react-redux'

const Alert = (props) => {
  const {display, severity, message} = props.Alert

  const handleClose = (event, reason) => {
    if (reason === 'clickaway')
      return;
    Store.dispatch({type: 'CLEAR'})
  }

  return (
    <div>
      <Snackbar
        open={display}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert elevation={6} variant='filled' onClose={handleClose} severity={severity}>
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
  )
}
export default connect(Reducer)(Alert)
