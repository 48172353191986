import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Store  from './reducer/store'
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import MoreVert from '@material-ui/icons/MoreVert';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import Button from '@material-ui/core/Button';
import SubmitButton from './SubmitButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {chpwd, SendModif, authenticate} from './userRequests'
import { capitalizeFirstLetter } from './utils'

const ProfileElem = ({title, value, divider, focused}) => {
  return (
    <Grid container direction='row'>
      <Grid item xs={3}>
        <Typography variant='overline'> {title.toUpperCase()} </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant='h6'> {value} </Typography>
      </Grid>
      {focused && (
      <Grid item>
        <MoreVert/>
      </Grid>
      )}
    </Grid>
  )
}

const FormDialog = ({open, onClose, title, children, submit, err, success}) => {
  const [submitted, setSubmitted] = React.useState(false)
  const onSubmit = (e) => {
    e.preventDefault()
    submit().then(async () => {
      await authenticate()
      success()
      onClose()
    }).catch(({response}) => {
      if (response) {
        err(response.data.errors)
        setSubmitted(false)
      } else {
        onClose()
        Store.dispatch({
          type: 'TRIGGER',
          message: 'No server response',
          severity: 'error'
        })
      }
    })
  }

  return (
    <Dialog fullWidth  maxWidth='xs' open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={onSubmit}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent> {children} </DialogContent>
        <DialogActions>
            <Button color="primary" onClick={onClose}>
              Annuler
            </Button>
            <SubmitButton type='submit' variant='contained' color="primary" submitted={submitted}>
              Valider
            </SubmitButton>
        </DialogActions>
      </form>
    </Dialog>
  )

}

const NameDialog = props => {
  const [name, setName] = React.useState(Store.getState().Reducer.log_info.name)

  const onSuccess = () => {
    Store.dispatch({
      type: 'TRIGGER',
      message: 'Nom changé',
      severity: 'success'
    })
  }
  return (
    <FormDialog
      title='Changer de nom'
      submit={() => SendModif(null, {infos: {name: name}})}
      success={onSuccess}
      {...props}>
      <Grid container spacing={1}>
        <Grid item xs>
          <TextField
            autoFocus
            required
            margin="dense"
            id="first"
            label="Prénom"
            value={name.first}
            onChange={(e) => setName({...name, first: e.target.value})}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField

            required
            margin="dense"
            id="last"
            label="Nom"
            value={name.last}
            onChange={(e) => setName({...name, last: e.target.value})}
            fullWidth
          />
        </Grid>
      </Grid>
    </FormDialog>
  )
}

const EmailDialog =  props => {
  const [mail, setMail] = React.useState(Store.getState().Reducer.log_info.email)
  const [err, setErr] = React.useState('')

  const onError = (err) => {
    setErr(err.email)
  }

  const onSuccess = () => {
    setErr('')
    Store.dispatch({
      type: 'TRIGGER',
      message: 'Email changé',
      severity: 'success'
    })
  }

  return (
    <FormDialog
      title="Changer d'Email"
      submit={() => SendModif(null, {infos: {email: mail}})}
      err={onError}
      success={onSuccess}
      {...props}>
      <TextField
        autoFocus
        required
        id="email"
        label="Email"
        type='email'
        error={Boolean(err)}
        helperText={err}
        value={mail}
        onChange={(e) => setMail(e.target.value)}
        fullWidth
      />
    </FormDialog>
  )
}

const PasswordDialog = props => {
  const defaultPwd = {former: '', latest: ''}
  const [password, setPassword] = React.useState(defaultPwd)
  const [err, setErr] = React.useState('')

  const onError = (err) => {
    setErr(err.former)
  }

  const onSuccess = () => {
    setErr('')
    setPassword(defaultPwd)
    Store.dispatch({
      type: 'TRIGGER',
      message: 'Mot de passe changé',
      severity: 'success'
    })
  }

  return (
    <FormDialog
      title='Changer de mot de passe'
      submit={() => chpwd(password.former, password.latest)}
      err={onError}
      success={onSuccess}
      {...props}>
      <Grid container spacing={1}>
        <Grid item xs>
          <TextField
            autoFocus
            required
            type='password'
            margin="dense"
            id="old"
            label="Ancien mot de passe"
            error={Boolean(err)}
            helperText={err}
            value={password.former}
            onChange={(e) => setPassword({...password, former: e.target.value})}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            required
            type='password'
            margin="dense"
            id="latest"
            label="Nouveau mot de passe"
            value={password.latest}
            onChange={(e) => setPassword({...password, latest: e.target.value})}
            fullWidth
          />
        </Grid>
      </Grid>
    </FormDialog>
  )
}

const ProfilePane = () => {
  const { email, name } = Store.getState().Reducer.log_info
  const [open, setOpen] = React.useState({
    name: false,
    email: false,
    password: false
  })
  const defaultFocus = {
    name: false,
    email: false,
    password: false
  }
  const [focused, setFocused] = React.useState(defaultFocus)

  const handleFocus = (event, key, val) => setFocused({...defaultFocus, [key]: val})

  return (
    <Paper>
      <List>
        <ListItem
        button
        onMouseEnter={(event) => handleFocus(event, "name", true)}
        onMouseLeave={(event) => handleFocus(event, "name", false)}
        onClick={() => setOpen({...open, name: true})}>
          <ProfileElem focused={focused.name} title="Nom" value={`${capitalizeFirstLetter(name.first)} ${name.last.toUpperCase()}`}/>
        </ListItem>
        <Divider/>
        <ListItem
        button
        onMouseEnter={(event) => handleFocus(event, "email", true)}
        onMouseLeave={(event) => handleFocus(event, "email", false)}
        onClick={() => setOpen({...open, email: true})}>
          <ProfileElem focused={focused.email} title="Email" value={email}/>
        </ListItem>
        <Divider/>
        <ListItem
        button
        onMouseEnter={(event) => handleFocus(event, "password", true)}
        onMouseLeave={(event) => handleFocus(event, "password", false)}
        onClick={() => setOpen({...open, password: true})}>
          <ProfileElem focused={focused.password} title="Mot de passe" value='••••••••'/>
        </ListItem>
      </List>
    <NameDialog open={open['name']} onClose={() => {setOpen({...open, name: false})}} />
    <EmailDialog open={open['email']} onClose={() => {setOpen({...open, email: false})}} />
    <PasswordDialog open={open['password']} onClose={() => {setOpen({...open, password: false})}} />
    </Paper>
  )
}

export default () => (
  <Container maxWidth="md" style={{padding:'5%'}}>
    <ProfilePane/>
  </Container>
)

