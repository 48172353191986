import React from 'react';
// import React, { useState } from 'react';

import { withRouter } from 'react-router-dom'

import Store  from './reducer/store'

import {SendModif, GetUser} from './userRequests'
import UserForm from './UserForm'

const EditUser = () => {
  const user = Store.getState().Reducer.SelectedPatient
  // console.log('MDR')
  // console.log(user)
  const defaults = {
    email: user.email,
    firstName: user.name.first,
    lastName: user.name.last,
  }

  const onSubmit = async (state) => {
    const send = {
      infos: {}
    }

    if (state.firstName !== defaults.firstName ||
        state.lastName !== defaults.lastName) {
      send.infos.name = {first: state.firstName, last: state.lastName}
    }
      
    if (state.email != defaults.email) {
      send.infos.email = state.email
    }

    if (state.password !== '') {
      send.infos.password = state.password
    }

    if (Object.getOwnPropertyNames(send.infos).length != 0) {
      return SendModif(user.id, send).then(() => {
        console.log(user)
        if (user.type === 'practician')
          Store.dispatch({type: 'RESET_DEFAULT_SELECTEDPATIEN'})
        else
          GetUser(user.id)
        Store.dispatch({
          type: 'TRIGGER',
          message: 'Utilisateur modifié',
          severity: 'success'
        })
      })
    }
    Store.dispatch({
        type: 'TRIGGER',
        message: 'Rien à faire',
        severity: 'warning'
    })
  }

  return (
    <div style={{marginTop: '32px'}}>
      <UserForm defaults={defaults}
        name="Modifier"
        required={{firstName: true, lastName: true, email: true}}
        onSubmit={onSubmit} />
    </div>
  )

}

export default withRouter(EditUser);
