import React,{ useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import store from '../../reducer/store'

// ALL COMMENT IS FOR VOLUME BAR

// import Replay from '@material-ui/icons/Replay';
// import VolumeDown from '@material-ui/icons/VolumeDown';
// import VolumeUp from '@material-ui/icons/VolumeUp';
// import Slider from '@material-ui/core/Slider';

import * as globalSound from '../../sound'
import { makeStyles } from '@material-ui/core/styles';
import Wait from './Wait';
import { rmaccent } from '../../utils'
import { Howl , Howler } from 'howler'
import { red } from '@material-ui/core/colors';
import { ClickAwayListener, withStyles } from '@material-ui/core';


import Word ,{WordClick} from './Word'


const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  icon: {
    color: 'rgba(0,0,0,0.54)',
  },
}));

// const VolumeBar = () => {
//   const classes = useStyles();
//   const [volume, setVolume] = React.useState(globalSound.appVolume() * 100)

//   const volumeChange = (_,  val) => {
//     globalSound.appVolume(val / 100)
//     setVolume(val)
//   }
//   return (
//     <Grid container direction='column' spacing={1} className={classes.container}>
//       <Grid item>
//         <VolumeUp fontSize="small" className={classes.icon} />
//       </Grid>
//       <Grid item xs>
//         <Slider value={volume} onChange={volumeChange} orientation='vertical' />
//       </Grid>
//       <Grid item>
//         <VolumeDown fontSize="small" className={classes.icon} />
//       </Grid>
//     </Grid>
//   )
// }

export default function SoundControl(props) {
  const classes = useStyles();
  const playSound = () => globalSound.playSound(props.sound)

  return (
    <Grid container direction='column' spacing={1} className={classes.container}>
      <Grid item>
        <IconButton onClick={playSound}> <RecordVoiceOverIcon /> </IconButton>
      </Grid>
      {/* Volume Bar
      {props.showVolume && (
        <Grid item xs>
          <VolumeBar />
        </Grid>
      )} */}
    </Grid>
  )
}

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}





function FindPlaySound(charSyll, syll, i, notFound,k,needWait = true)
{
  let finish = false

  const notLoadSyll = () => {
    console.warn("sound not found ", charSyll, syll,i)
    notFound = charSyll[i]
    finish =  true
  }

  const notLoadCharSyll = () =>{
    if (charSyll.length == syll.length){
    sound = new Howl({
      src: process.env.PUBLIC_URL + '/2_son4/' + syll[i] + '.wav',
      onloaderror: function () {notLoadSyll()},
      onplay : function () {
        finish =true
      }
      })
    globalSound.playSound(sound)
    }
    else{notLoadSyll()}
  }

  if(!charSyll[i])
    return
  let sound = null
  if(needWait){ // si needWait == true sa veut dire que on vas jouer une lettre
  const l = charSyll[i][k]
  const lp =  ((l == 'é' || l  == 'è') ?  l : rmaccent(l))
  sound = new Howl({ src: process.env.PUBLIC_URL + '/2_son4/' + lp + '.wav',
  onloaderror: function () {console.warn("letter sound not foud ",sound._src)} })
  
  globalSound.playSound(sound)

  if(charSyll[i][k + 1])
    return ({i : i, notFound : notFound, k : k+1,needWait:true})
  else
    return ({i:i,notFound: notFound,k : k,needWait:false})


  }
  else{
    sound = new Howl({
      src: process.env.PUBLIC_URL + '/2_son4/' + (notFound + charSyll[i]) + '.wav' ,
      onloaderror: function () {notLoadCharSyll()},
      onplay: function () {
        finish =true
      }
    })
    globalSound.playSound(sound)
    if (!charSyll[i +1 ])
      return;
    else
      return ({i:i+1,notFound: notFound,k:0,needWait:true})
  }
  }

  function useInterval(callback, delay) { // https://overreacted.io/fr/making-setinterval-declarative-with-react-hooks/
    const savedCallback = useRef();

    // Se souvenir de la dernière fonction de rappel.
    useEffect(() => {
      savedCallback.current = callback;
    });

    // Configurer l’intervalle.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);



  }

export function SylableMulty(props) {
  const reducer = store.getState().Reducer
      let [disp, setdisp] = useState('');
      const charSyll =  reducer.list[props.wIdx].charSyll.split('-')//reducer.list[props.wIdx].orthosyll.split('-') // si le decoupage et fait  il faut changer .orthosyll 
      const syll = reducer.list[props.wIdx].syllSon.split('-') //reducer.list[props.wIdx].syll.split('-') // si le decoupage et fait  il faut changer .syll
      let [notFound, setNotFound] = useState('');
      let [k, setK] = useState(0);
      let [i, setI] = useState(0);
      let [needWait, SetNeedWait] = useState(true);
      let tmpArg = { i :Number, notFound : String , k: Number,needWait : Boolean}
      let v = 0


      let [charBlinck,setCharBlinck] = useState({mot : reducer.list[props.wIdx].mot , blinck : [] , turn : false})

      let delayBlinck = 250
      useInterval(() => { // the blinck
        let strDisp = charBlinck.mot

        if (charBlinck.turn ==  false && charBlinck.blinck.length > 0)
        {
        if(isNaN(charBlinck.blinck[0]) == false)
        {
          for (const i in charBlinck.blinck){
             strDisp = strDisp.substring(0, charBlinck.blinck[i]) + ' ' + strDisp.substring( charBlinck.blinck[i] + 1);
        }
      }
            setCharBlinck({...charBlinck,turn  : true })
        }
        else{
          setCharBlinck({...charBlinck,turn  : false })
        }
        setdisp(strDisp)
      },delayBlinck);

      useInterval(() => {
        if(needWait == false)
        {
          let tmpArray = [];
          v = 0
          for (let index = 0; index <= i -1; index++) {
              v = v + charSyll[index].length
      }
      for (let tmpk = 0; tmpk <= k; tmpk++) {
        tmpArray.push(v + tmpk)
      }
      setCharBlinck({ ...charBlinck, blinck: tmpArray })
        }
        else
        {
        for (let index = 0; index < (i ? i :charSyll.len) ; index++) {
          v = v + charSyll[index].length
        }
        v = v + k
        setCharBlinck({...charBlinck, blinck : [v]})
      }
        tmpArg = {...FindPlaySound(charSyll ,syll, i ,notFound, k , needWait)}
        setI(tmpArg.i)
        setNotFound(tmpArg.notFound)
        setK(tmpArg.k)
        SetNeedWait(tmpArg.needWait)
      }, 1000);
      return (
      <Word word={disp}/>);
  }



